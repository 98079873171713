import { useState, useContext, useMemo } from "react";

export function usePersistentState(key, initialValue) {
    const fullkey = `MeducarPWA::${key}`
    
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(fullkey)
            if (!item) {
                window.localStorage.setItem(fullkey, JSON.stringify(initialValue))
                return initialValue;
            }
            return JSON.parse(item);
        } catch (err) {
            console.error(err)
            return initialValue
        }
    })

    const setValue = value => {
        try {
            setStoredValue(value)
            window.localStorage.setItem(fullkey, JSON.stringify(value))
            return value;
        } catch (err) {
            console.error(err)
        }
    }

    return [ storedValue, setValue ]
}