import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';
import App from './App';

Sentry.init({
    dsn: "https://667b7eea6bec4341a4ad61138079d315@o4504537557630976.ingest.sentry.io/4504537559859200",
    integrations: [new BrowserTracing()],
    
    environment: process.env.NODE_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

import configureStore from './redux/store';
const store = configureStore();

ReactDOM.render(
    <React.StrictMode>
        <App store={store} />
    </React.StrictMode>
    , 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
