

export const PatientTags = ({patient}) => {
  return (
    <>
        { patient.tags && (patient.tags.length > 0) && (
            <div className="text-sm text-center pt-1 pl-1 uppercase font-sans">
              { 
                patient.tags.map((element) => (
                  <div style={{ backgroundColor: element.tag.color, border: 'none' }} key={element.tag.id} className="mr-1 mb-1 badge">
                    {element.tag.name}
                  </div>
                ))
              }
            </div>
        )}
    </>
  )
}
