import { el } from 'date-fns/locale'
import React from 'react'
import KeyValue from '../_Utils/KeyValue';

export const Default = (props) => {

  console.log(props);

  if (!props.struct.items) { return(<></>) }

  return (
    <>
      {
        props.struct.items.map((el) => (

          ( props.data[el.name] != "" &&
            <div key={ el.name }>
              <KeyValue className="mb-1 text-lg font-semibold" label={ el.settings.label } value={ props.data[el.name] } unit={ el.settings.unit } />
            </div>
          )

        ))
      }
    </>
  )
}

