import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePersistentState } from "../hooks/usePersistentState";

const AuthContext = React.createContext(null);

export const useAuth = () => {
    return React.useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [token, setToken] = usePersistentState("token", null);
  
    const handleLogin = (token) => {
        setToken(token);
        navigate('/patients');
    };
  
    const handleLogout = () => {
        setToken(null);
        // navigate('/login');
    };
  
    const value = {
        token,
        handleLogin,
        handleLogout
    };
  
    return (
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    );
};

export default AuthProvider;