function getUser() {
    let result = localStorage.getItem('userData');
    if (result) {
        result = JSON.parse(result);
    }
    return result;
}
function setUser(userData) {
    return localStorage.setItem('userData', userData);
}

function getUserPatients() {
    let result = [];
    let auxResult = localStorage.getItem('userData');
    if (auxResult) {
        auxResult = JSON.parse(auxResult);
        if (auxResult.patient_ids) {
            result = String(auxResult.patient_ids).split(',');
        }
    }
    return result;
}
function setUserPatient(patientData) {
    let arrPatients = [];
    if (patientData) {
        let auxPatients = localStorage.getItem('userPatients');
        if (auxPatients) {
            arrPatients = JSON.parse(auxPatients);
        }
        arrPatients.push(patientData);
    }
    return localStorage.setItem('userPatients', JSON.stringify(arrPatients));
}

function getCurrentOffice() {
    return localStorage.getItem('currentOffice');
}
function setCurrentOffice(officeData) {
    return localStorage.setItem('currentOffice', officeData);
}

function getToken() {
    const token = localStorage.getItem('userToken');
    return token ? token : '';
}
function setToken(userToken) {
    return localStorage.setItem('userToken', userToken);
}

function logout() {
    setUser('');
    setToken('');
    setCurrentOffice('');
    return true;
}

const session = {
    getUser: getUser,
    setUser: setUser,
    getUserPatients: getUserPatients,
    setUserPatient: setUserPatient,

    getCurrentOffice: getCurrentOffice,
    setCurrentOffice: setCurrentOffice,

    getToken: getToken,
    setToken: setToken,
    
    logout: logout,
};
export default session;
