import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";

import { getPatients, resetPatients, useEditButton, cleanInfoPatient } from "../redux/actions/patients";
import { setPageTitle, setShowHeader, setShowFooterMenu } from "../redux/actions/layout";
import {
  patientsCurrentPage,
  patientsLastPage,
  patientsResult,
} from "../redux/selectors";

import InfiniteList from "../components/InfiniteList";
// import InfoPatient from "./InfoPatient";
import SectionLoading from '../components/SectionLoading';
import SectionEmpty from '../components/SectionEmpty';

import session from "../utils/session";
import { getTags } from "../redux/actions/offices";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  search: {
    width: "100%",
    marginTop: "15px",
  },
}));

export default ({ loggedIn }) => {
  const navigate = useNavigate();
  const pageClasses = useStyles();
  const dispatch = useDispatch();

  const [ loadCompleted, setLoadCompleted ] = useState(false);

  const patientName = useSelector((state) => state.patients.patientName);
  const patients = useSelector((state) => patientsResult(state));
  const totalPatients = useSelector((state) => state.patients.totalPatients);
  const patientsError = useSelector((state) => state.patients.error);
  const currentPage = useSelector(state => state.layout.currentPage);
  const previousPage = useSelector(state => state.layout.previousPage);
  const dataCurrentPage = useSelector((state) => patientsCurrentPage(state));
  const lastPage = useSelector((state) => patientsLastPage(state));
  const loading = useSelector((state) => state.patients.isLoading);

  const tags = useSelector((state) => state.offices.tags);

  const [pageNumber, setPageNumber] = useState(1);
  const [name, setName] = useState("");
  const [hasMore, setHasMore] = useState(false);

  const [patientId, setPatientId] = useState(null);

  const [isSearch, setIsSearch] = useState(false);

  const { professional: professionalData, offices } = session.getUser();

  let time = 0;

  const professional_id = professionalData.id;

  const inputSearch = useRef(null);

  const getData = (pageNumber = 1, newName = "", reset = false) => {

    // console.log('pageNumber', pageNumber);

    let Data = {
      name: newName,
      professional_id,
      limit: 50,
      pageNumber,
      reset
    };
    dispatch(getPatients(Data));

  };

  const getTitle = () => {
    return (
      <div className="px-0 font-sans">
        Pacientes
        {totalPatients > 0 && (
          <span>{` (${totalPatients})`}</span>
        )}
      </div>
    );
  };

  useEffect(() => {
    // if (!patientsError && loggedIn) {
    //   console.log(patients);
    //   if (patientName !== name || !patients) {
    //     getData();
    //   }
    // } 
    
    if (currentPage != '/patients') {
      // Refresco los datos pq vengo de otra pantalla
      getData(1, '', true);
      dispatch(setPageTitle(getTitle()));
    }

    if(!tags) {
      let officeId = 0;
      if (offices) {
        // TOGO_G: Hasta modificar el endpoint ... pido los turnos del primer consultorio
        officeId = offices[0].id;
      }
      dispatch(getTags({officeId: officeId}));
    }
    // dispatch(setPageTitle(getTitle()));
  }, []);

  useEffect(() => {
    //setHasMore(currentPage < lastPage);
    dispatch(setPageTitle(getTitle()));
    dispatch(setShowHeader(true));
    }, [totalPatients]);
  
  useEffect(() => {
    setHasMore(dataCurrentPage < lastPage);
  }, [patients]);

  useEffect(() => {
    if (pageNumber > 1) {
      if (patients.length > 0) {
        getData(pageNumber);
      }
    }
  }, [pageNumber]);

  useEffect(() => {
    if (name !== "") {
      getData(1, name);
    }
  }, [name]);

  useEffect(() => {
    if (!loading && isSearch) {
      setIsSearch(false);
    }
    if (!loading && !loadCompleted) {
      setLoadCompleted(true);
    }
  }, [loading]);

  const handleChange = (e) => {
    let finalValue = "";
    const value = e.target.value;
    if (time) clearTimeout(time);

    time = setTimeout(() => {
      finalValue = value.replace(/ /g, "_");
      if (finalValue == "") {
        getData();
        setIsSearch(false);
      } else {
        setIsSearch(true);
      }
      setName(finalValue);
    }, 250);
  };

  const handleClearName = () => {
    inputSearch.current.value = "";
    setName("");
    setIsSearch(false);
    getData();
  };

  //--- Patient Detail -->
  const handleClickPatient = (id) => {
    navigate(id)
    // dispatch(setShowHeader(false));
    // setPatientId(id);
  };
  const handleClosePatient = () => {
    setPatientId(null);
    dispatch(setShowHeader(true));
    dispatch(setShowFooterMenu(true));
    dispatch(cleanInfoPatient());
    console.log(patients);
    //getData(1, '', true);    
  };
  //<-- Patient Detail ---

  return (
    <>
      {
        loadCompleted && (
          <div className="py-20 h-screen px-2">
            <div className="flex pb-4 px-4">
              <div className="grow">
                <input
                  ref={inputSearch}
                  type="text"
                  className="font-sans text-lg w-full h-12 rounded-[16px] focus:outline-none px-6 shadow-[0px_3px_10px_2px_rgba(0,0,0,0.1)]"
                  placeholder="Buscar"
                  onChange={(e) => handleChange(e)}
                />
                {name ? (
                  <i
                    className="far text-lg fa-times-circle mt-3 -ml-8 text-red-400 cursor-pointer"
                    onClick={() => handleClearName()}
                  ></i>
                ) : (
                  <i className="fas text-lg fa-search absolute right-3 top-4 text-gray-300"></i>
                )}
              </div>
              {/* <div className="flex-none ml-2">
                <Link to="/new-patient">
                  <button className="bg-[#3388FF] text-white text-lg w-12 h-12 rounded-lg">+</button>
                </Link>
              </div> */}
            </div>

            <div className="max-h-full max-w-md mx-auto overflow-scroll md:max-w-lg mt-2">
              <div className="md:flex">
                <div className="w-full p-0">
                  <div className="relative">
                    {/* <input ref={ inputSearch } type="text" className="w-full h-12 rounded focus:outline-none px-3 focus:shadow-md" placeholder="Buscar..." onChange={(e) => handleChange(e)} /> */}

                    {/* {
                                name
                                ?
                                  <i className="far fa-times-circle absolute right-3 top-4 text-red-400" onClick={ () => handleClearName() }></i>
                                :
                                  <i className="fas fa-search absolute right-3 top-4 text-gray-300"></i>
                              }                       */}

                    { !isSearch && !loading && name && totalPatients > 0 && (
                      <div className="flex justifyenter">
                        <div className="font-sans flex mt-2 text-gray-400">
                          {`${totalPatients} resultado${
                            totalPatients > 1 ? "s" : ""
                          } para la búsqueda`}
                        </div>
                      </div>
                    )}

                    { isSearch && loading && name && (
                      <div className="flex justify-center">
                        <div className="font-sans flex mt-2 text-gray-400">
                          Buscando{" "}
                          <span className="font-sans font-bold text-black ml-1">{name}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <ul>
                    <InfiniteList
                      name={name}
                      data={patients}
                      hasMore={hasMore}
                      setPageNumber={setPageNumber}
                      loading={ (loadCompleted && loading) }
                      isSearch={isSearch}
                      getData={getData}
                      handleClickPatient={(id) => { console.log(id); navigate(id.toString()) /* handleClickPatient(id) */} }
                    />
                  </ul>
                  
                </div>
              </div>
            </div>
          </div>
        )
      }      

      <SectionLoading loading={ (!loadCompleted && loading) }/>
        
      <SectionEmpty show={ (loadCompleted && name != '' && totalPatients == 0) } title={ 'No se encontraron resultados' } subtitle={ `al buscar "${name}"` } />
      <SectionEmpty show={ (loadCompleted && name == '' && totalPatients == 0) } title={ 'No se encontraron resultados' } />

      
      
      {
        patientId && (
          <div
            className="fixed inset-0 bg-opacity-50 overflow-y-auto h-full w-full"
            id="my-modal"
          >
            {/* <InfoPatient id={patientId} handleClosePatient={handleClosePatient} /> */}
          </div>
        ) 
      }
      {/* 
      : (
        <Link to="/new-patient" className="link">
          <Fab color="primary" aria-label="add" className={pageClasses.fab}>
            <AddIcon />
          </Fab>
        </Link>
      )} 
      */}
    </>
  );
};
