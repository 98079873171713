import axios from 'axios';
import session from "../../utils/session";

const baseUrl =  process.env.REACT_APP_API_URL;

const getToken = () => {
    return localStorage.getItem('userToken');
}



const header = () => {
    return {
        timeout: 10000,
        Authorization: 'Bearer ' + getToken(),
        
    }
};


// 'Content-Type': 'application/x-www-form-urlencoded',

//Arreglar header
export const apiCall = (url, data, headers, method) => axios({
    method,
    url: baseUrl + url,
    data,
    headers: headers ? headers : header()
});


function _getNewToken() {
    return new Promise((resolve, reject) => {
 
      const config = {
        headers: header()
      }

      axios
        .patch(`${baseUrl}/api/auth/refresh`, null, config)
        .then(response => {
          localStorage.setItem('userToken', response.data.data.token);

          resolve(response.data.data.token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  axios.interceptors.response.use( (response) => {
    // Return a successful response back to the calling service
    return response;
  }, (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Return any error which is due to authentication back to the calling service

    if (error.config.url.endsWith('/auth/login')) {

      if (error.response.status === 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

    }
  
    // Try request again with new token
    return _getNewToken()
      .then((token) => {
        // New request with new token
        const config = error.config;
        config.headers['Authorization'] = `Bearer ${token}`;
        return new Promise((resolve, reject) => {
          axios.request(config).then(response => {
            resolve(response);
          }).catch((error) => {
            reject(error);
          })
        });
  
      })
      .catch((error) => {

        if (error.response) {
            if (error.response.status == 500) {

              console.log('ERROR 500', error.config.url);
  
              if (error.config.url.endsWith('/auth/refresh')) {
                //TODO_G: VER
                console.log('REFRESH TOKEN');  
                console.log('Sesión vencida');
                session.logout();
                return window.location.href = '/login'
              } else {
                //TODO_G: VER
                console.log('ERROR', error.response.data.message);
              }            
            }
          
          } else {
          console.log('ERROR: ', error.response);
          console.log('VER QUÉ HACER EN ESTE CASO');
        }
        // reject(error);
      });
  });
