import { Box, Container, Typography, Button, Card, CircularProgress, Checkbox, OutlinedInput, ListItemText, Chip } from '@material-ui/core';
import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';

import PatientHeader from '../components/PatientHeader';
import { Height } from '@material-ui/icons';
import {setBackButton, setPageTitle} from '../redux/actions/layout';
import {getEventOptions, changeTags} from '../redux/actions/offices';

import Select from "@material-ui/core/Select";

import MenuItem from "@material-ui/core/MenuItem";

import session from '../utils/session';


import 'moment/locale/es';
import Moment from 'react-moment';
import { getInfoPatient } from '../redux/actions/patients';

const useStyles = makeStyles(theme => ({
    info: {
      minHeight: 'calc(60vh - 56px)',
      padding: '8px'
    },
    data: {
        height: 'calc(18vh/3 - 1px)'
    }

  }));
  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };



const InfoAppointment = ({appointment, handleCloseAppointment}) => {
    const dispatch = useDispatch();
    const eventOptions = useSelector(state => state.offices.eventOptions);
    const patient = useSelector((state) => state.patients.patient);

    const allTags = useSelector(state => state.offices.tags);

    const [appointmentTags, setAppointmentTags] = useState([]);
    const [tags, setTags] = useState(null);

    const { professional:professionalData } = session.getUser();
    const professional_id = professionalData.id;

    const [selectedTag, setSelectedTag] = useState(0);

    const [currentSection, setCurrentSection] = useState('info');



    useEffect(() => {
        dispatch(setBackButton(true));
        dispatch(setPageTitle('Turno'))
        if(!eventOptions) {
            const data = {
                professional_id,
        
              }
              dispatch(getEventOptions(data));
        }

        let temporalTags = [];

        appointment.tags.forEach(tag => {
          temporalTags.push(tag.tag.id);
        });
        setTags(appointment.tags);

        console.log(temporalTags);
        setAppointmentTags(temporalTags);
        dispatch(getInfoPatient({ id: appointment.patient.id, professional_id }));
    }, []);

    const pageClasses = useStyles();

    // const values = useLocation().state.el;

    // console.log(values);

    console.log(patient)

    console.log(appointment)

    //const patient = appointment.patient;
    const fullDate = appointment.date_from;
    const consultation_type_id = appointment.consultation_type_id;
    const office_id = appointment.office_id;
    const appointment_state = appointment.state;
    const id = appointment.id;
        

    const date = (fullDate.slice(0,10));
    const time = (fullDate.slice(11,16));
    if (!eventOptions) {
        return <CircularProgress />
    };
    const consultation_type = eventOptions.data.consultation_types[consultation_type_id];
    const office_name = eventOptions.data.offices[office_id];

    console.log(office_name.name);
    console.log(consultation_type);

    const showStatus = (value) => {
      switch(value) {
        case 0:
          return 'Sin Confirmar';
        case 1:
          return 'Confirmado';
        case 2:
          return 'Sala de espera';
        case 50:
          return 'Atendido';
        case 80:
          return 'No asistio'
        default:
          return 'Error';
      }
    }

    const handleChangeTags = (event) => {

      setSelectedTag(event.target.value);


      // const {value} = event.target;
      // console.log(value.length, appointmentTags.length)
      // let intersection = [];
      // let operation = '';
      // if(value.length > appointmentTags.length) {
      //   //Se agrega tag
      //   console.log('AGREGAR')
      //   intersection = value.filter(x => !appointmentTags.includes(x));
      //   operation = 'add';
      // } else if(value.length < appointmentTags.length) {
      //   //Se quita tag
      //   console.log('QUITAR')
      //   intersection = appointmentTags.filter(x => !value.includes(x));
      //   operation = 'remove';
      // }
  
      const data = {
        type: 'event',
        tagId: event.target.value, 
        typeValue: id,
        operation: 'add',
        officeId: 326
      }
      
      dispatch(changeTags(data));
      console.log(tags)
      let tagsValues = appointmentTags;
      tagsValues.push(event.target.value);

      setAppointmentTags(tagsValues);
    }

    const removeTag = (tagId) => {
      const data = {
        type: 'event',
        tagId, 
        typeValue: id,
        operation: 'remove',
        officeId: 326
      }
  
      dispatch(changeTags(data));
      
      setTags(tags.filter(tag => tag.tag_id != tagId));
      let tagsValues = appointmentTags.filter(tag => tag != tagId);
  
      setAppointmentTags(tagsValues);

    }


    const startAppointment = () => {
      alert('INICIAR')
    }

    const handleClickSection = (section) => {
      setCurrentSection(section);
    }
  

    return (
      <>
        {!patient && <p>Loading...</p>}
        {patient && 
        <>
          <div className="py-2 h-screen bg-gray-300 px-2 overflow-x-auto">
            {/* <div className="max-w-md mx-auto bg-gray-100 shadow-lg rounded-lg overflow-hidden md:max-w-lg">
              <div className="md:flex">
                <div className="w-full p-4"> */}

            <div className="card bg-gray-100 shadow-lg rounded-lg compact">
              <figure>
                <div className="h-[200px] mb-3">
                  <img src="https://picsum.photos/id/1005/400/250" />
                </div>                
              </figure>
              <div className="card-body">
                <h2 className="card-title">
                  {patient.surname}, {patient.name}{" "} 
                  <span className="text-sm text-gray-400">
                    <Moment fromNow>{patient.born_date} </Moment>
                  </span>
                </h2>

                <div className='p-2 my-3 bg-gray-200'>
                  <div>Consultorio: <span className="font-bold">{office_name.name ? office_name.name : "--"}</span></div>            
                  <div>Tipo de consulta: <span className="font-bold">{consultation_type ? consultation_type : "No valido"}</span></div>
                  <div>Dia: <span className="font-bold"><Moment format="DD-MM-YYYY">{appointment.date_from}</Moment></span></div>
                  <div>Hora: <span className="font-bold"><Moment format="HH:mm">{appointment.date_from}</Moment></span></div>
                  <div>Observaciones: <span className="break-all">{appointment.observations ? appointment.observations : '--'}</span></div>

                  <div className="mb-2 w-full mt-4 mx-auto">
                    <button onClick={() => startAppointment()} className="btn btn-block ">Iniciar Turno</button>
                  </div>
                </div>

                <p>
                  <span className="text-sm text-gray-400">Teléfono</span>
                  <span className="text-sm font-bold text-black ml-2">
                    {patient.telephone ? patient.telephone : "--"}
                  </span>
                  <br />
                  <span className="text-sm text-gray-400">Email</span>
                  <span className="text-sm font-bold text-black ml-2">
                    {patient.email ? patient.email : "--"}
                  </span>
                  <br />
                  <span className="text-sm text-gray-400">Dirección</span>
                  <span className="text-sm font-bold text-black ml-2">
                    {patient.address ? patient.address : "--"}
                  </span>
                </p>

              </div>
            </div>

            <div className="card bg-gray-100 rounded-lg compact sticky top-10 mt-2 z-20 text-center">
              <div className="flex flex-row justify-center py-2 px-5">
                <button className={`btn btn-sm w-1/3 mx-1 btn-${ currentSection=='info'           ? 'primary' : 'ghost' }`} onClick={ () => handleClickSection('info')          }>Info</button>
                <button className={`btn btn-sm w-1/3 mx-1 btn-${ currentSection=='events'         ? 'primary' : 'ghost' }`} onClick={ () => handleClickSection('events')        }>Turnos</button>
                <button className={`btn btn-sm w-1/3 mx-1 btn-${ currentSection=='prescriptions'  ? 'primary' : 'ghost' }`} onClick={ () => handleClickSection('prescriptions') }>Recetas</button>
              </div>
            </div>



            { (currentSection == 'info') && ( 
              <div className="card bg-gray-200 shadow-lg rounded-lg compact mt-3">

              <div className="card-body">
                  <h2 className="card-title">
                    Datos personales
                  </h2>
                  <div className="mt-2">
                    <table className="table w-full table-compact">
                      {/* <thead>
                        <tr>
                          <th>DNI</th>
                          <th>OBRA SOCIAL</th>
                        </tr>
                      </thead> */}
                      <tbody>
                        <tr>
                          <td width="30%">DNI</td>
                          <td className="font-semibold">{ patient.id_number ? patient.id_number : '--' }</td>
                        </tr>
                        <tr>
                          <td>OBRA SOCIAL</td>
                          <td className="font-semibold">{ patient.medical_security ? patient.medical_security : '--' }</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <hr className="border-b border-stone-300 my-2"/>

                  <div className="card-body">
                    <h2 className="card-title">
                      Etiquetas{" "}
                      <span className="text-sm text-gray-400">{`${
                        tags ? tags.length : "0"
                      }`}</span>
                    </h2>

                  <div className="text-sm">
                    {tags
                      ? tags.map((tag) => (
                          <div
                            key={`patient-tag-${tag.tag.id}`}
                            style={{
                              backgroundColor: tag.tag.color,
                              border: "none",
                            }}
                            className="mr-2 mb-2 badge"
                          >
                            {tag.tag.name}
                          </div>
                        ))
                      : null}
                  </div>

                  <label
                    htmlFor="my-modal-2"
                    className="btn btn-sm modal-button mt-3"
                  >
                    Agregar / Quitar
                  </label>

                  <input
                    type="checkbox"
                    id="my-modal-2"
                    className="modal-toggle"
                  />
                  <div className="modal">
                    <div className="modal-box">
                      <div className="container bg-gray-200 shadow-lg rounded-lg p-2 mb-4">
                        {tags
                          ? tags.map((tag) => (
                              <div
                                key={`patient-tag-${tag.tag.id}`}
                                style={{
                                  backgroundColor: tag.tag.color,
                                  border: "none",
                                }}
                                className="mr-2 mb-2 badge w-full"
                              >
                                <svg
                                  onClick={() => removeTag(tag.tag.id)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  className="inline-block w-4 h-4 stroke-current"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                  ></path>
                                </svg>
                                {tag.tag.name}
                              </div>
                            ))
                          : null}
                      </div>

                      <select
                        value={selectedTag}
                        className="select w-full max-w-xs"
                        onChange={handleChangeTags}
                      >
                        <option value={0} selected>
                          Selecciona una etiqueta para agregar.
                        </option>
                        {allTags.map((tag) =>
                          !appointmentTags.includes(tag.id) ? (
                            <option key={`event-tag-${tag.id}`} value={tag.id}>{tag.name}</option>
                          ) : null
                        )}
                      </select>

                      {/* <p>El paciente tiene {patient.tags.length} etiquetas.</p> */}
                      {/* <Select
                      id="demo-multiple-checkbox"
                      className="w-full"
                      multiple
                      value={appointmentTags}
                      onChange={handleChangeTags}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((tagId) => (
                            <Chip key={tagId} label={allTags?.find(e => e.id === tagId).name} />
                            ))}
                        </Box>
                        )}
                      MenuProps={MenuProps}
                    >
  
                      {allTags.map((tag) => (
                        <MenuItem key={tag.id} value={tag.id}>
                          <Checkbox checked={appointmentTags.includes(tag.id)} />
                          <ListItemText primary={tag.name} />
                        </MenuItem>
                      ))}
                    </Select> */}
                      <div className="modal-action">
                        <label htmlFor="my-modal-2" className="btn">
                          Cerrar
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              )}


            {currentSection == 'events' && (
              <div className="card bg-gray-200 shadow-lg rounded-lg compact mt-3">
                <div className="card-body">
                  <h2 className="card-title">
                    Turnos <span className="text-sm text-gray-400">{`${patient.events_count ? patient.events_count : '0'}`}</span>
                  </h2>

                  <div className="mt-2">
                    <table className="table w-full table-compact table-fixed">
                      <tbody>

                        <tr>
                          <td colSpan={3} className="font-bold bg-gray-200">Próximos turnos</td>
                        </tr>
                        
                        {patient.next_5_events.map((item, index) => (
                          <tr key={`patient-next-event-${index}`}>
                            <td className="text-center truncate">
                              <div><Moment format="DD-MM-YYYY">{item.date_from}</Moment></div>
                              <div><Moment format="HH:mm">{item.date_from}</Moment></div>
                            </td>
                            <td className="truncate text-center">{item.ctype.name}</td>
                            <td className="text-center">
                              <span className="badge badge-primary">{item.state}</span>
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={3} className="font-bold bg-gray-200">Turnos anteriores</td>
                        </tr>

                        {patient.lastest_5_events.map((item, index) => (
                          <tr key={`patient-lastest-event-${index}`}>
                            <td className="text-center">
                              <div><Moment format="DD-MM-YYYY">{item.date_from}</Moment></div>
                              <div><Moment format="HH:mm">{item.date_from}</Moment></div>
                            </td>
                            <td className="truncate text-center">{item.ctype?.name}</td>
                            <td className="text-center">
                              <span className="badge badge-primary">{item.state}</span>
                            </td>
                          </tr>
                        ))}

                      </tbody>
                    </table>

                    <button className="btn btn-sm btn-block mt-3">Ver todos</button>
                  </div>
                </div>
              </div>
            )}

  { /* RECETAS */ }
            { (currentSection == 'prescriptions') && (
              <div className="card bg-gray-200 shadow-lg rounded-lg compact mt-3">
                <div className="card-body">
                  <h2 className="card-title">
                    Recetas <span className="text-sm text-gray-400">{`${patient.prescriptions_count ? patient.prescriptions_count : '0'}`}</span>
                  </h2>

                  <div className="mt-2">
                    <table className="table w-full table-compact">
                      <tbody>
                        <tr className="text-center font-bold">
                          <td>Fecha</td>
                          <td>Descripcion</td>
                          <td>Diagnostico</td>
                        </tr>

                        {patient.prescriptions ? patient.prescriptions.map((item) => (
                          <tr key={`prescriptions-${item.id}`}>      
                            <td className="text-center">
                              <div><Moment format="DD-MM-YYYY">{item.date}</Moment></div>
                            </td>
                            {/* <td className="text-center">{item.drugs.name}</td> */}
                            <td className="text-center">{item.description}</td>
                            <td className="text-center">
                              {item.diagnosis}
                              {/* <span className="badge badge-primary">{item.drugs.days} dias - c/{item.drugs.hours}hs</span> */}
                            </td>
                          </tr>
                        )) : null}
                        

                      </tbody>
                    </table>

                    <button className="btn btn-sm btn-block mt-3">Ver todos</button>
                  </div>
                </div>
              </div>
            )}





          
            <div className="card bg-gray-200 shadow-lg rounded-lg compact mt-3">

            </div>
          

            <button
              className="btn btn-circle btn-sm absolute top-3 left-3"
              onClick={handleCloseAppointment}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

          
          </div>
        </>
        }
      </>
    );
}

export default InfoAppointment;