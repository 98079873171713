import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch, Provider } from "react-redux";
import PropTypes from "prop-types";

import { useLocation } from "react-router";

import {
  setBackButton,
  openSidebar,
  setShowHeader,
  setShowFooterMenu,
  setCurrentPage,
} from "./redux/actions/layout";
import { setCurrentOffice } from "./redux/actions/offices";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


import Sidebar from "./layout/Sidebar";
import HeaderTransparent from "./layout/HeaderTransparent";

import { themeAppDefault, useStylesApp } from "./appStyles";

import AuthProvider from "./components/AuthProvider";
import ProtectedRoute from './components/ProtectedRoute';
import Login from "./pages/Login";

import Patients from "./pages/Patients";
import InfoPatient from "./pages/InfoPatient";

import Appointments from "./pages/Appointments";

import Settings from "./pages/Settings";
// import NewPatient from "./pages/NewPatient";
// import Logout from "./pages/Logout";
// import Dashboard from "./pages/Dashboard";
// import NewAppointment from "./pages/NewAppointment";
// import InfoAppointment from "./pages/InfoAppointment";
// import Prescriptions from "./pages/Prescriptions";
// import NewPrescription from "./pages/NewPrescription";

import { userToken } from "./redux/selectors";

import { saveData } from "./redux/actions/login";

import session from "./utils/session";
import FooterMenu from "./components/FooterMenu";
import NotFound from "./pages/NotFound";
// import InfoPrescription from "./pages/InfoPrescription";

const App = ({ store }) => {
  const classes = useStylesApp();

  const [themeDefault, setThemeDefault] = useState(themeAppDefault);
  const [themeCustom, setThemeCustom] = useState(null);
  const [cssAppContent, setCssAppContent] = useState('');

  //const [currentOffice, setCurrentOffice] = useState(null);
  // const [currentPage, setCurrentPage] = useState(null);

  // const location = useLocation();

  useMemo(() => {
    if (!window.matchMedia('(display-mode: standalone)').matches) {
      console.log("DESDE WEB");
      setCssAppContent('app-content');
    }
  }, [window]);

  

  let sidebarOpen = null;

  if (store.getState().layout) {
    sidebarOpen = store.getState().layout.sidebarOpen;
  }

  useEffect(() => {
    console.log("process.env.NODE_ENV", process.env.NODE_ENV);

    // Mostrar loading mientras carga data del consultorio y theme
    console.log("Mostrar loading mientras carga data del consultorio y theme");

    // isLoggedIn();

    const themeCustom = {
      palette: {
        primary: { main: "#16A692" },
        secondary: { main: "#000" },
        type: "light",
      },
    };
    setThemeCustom(themeCustom);
  }, []);

  useEffect(() => {
    store.dispatch(setCurrentPage(location.pathname));
  }, [location.pathname]);

  const handleLogin = () => {
    // setLoggedIn(true);
    // // Muestro el Header sólo si está dentro de la app
    // setShowHeader(true);
    isLoggedIn();
  };
  const handleLogout = () => {
    session.logout();
    setLoggedIn(false);
    // Oculto el Header en el Login
    store.dispatch(setShowHeader(false));
  };

  // const isLoggedIn = () => {
  //   let result = true;

  //   let token = session.getToken();

  //   if (token) {
  //     result = patientGetInfo();
  //   } else {
  //     if(store.getState().login) {
  //       token = store.getState().login.userToken;
  //       if(token) {
  //         result = patientGetInfo();
  //       } else {
  //         result = false;
  //       }
  //     } else {
  //       result = false;
  //     }
      
  //   }
  //   setLoggedIn(result);
    
  //   // Muestro el Header sólo si está dentro de la app
  //   store.dispatch(setShowHeader(result));
  //   // Muestro el FooterMenu sólo si está dentro de la app
  //   store.dispatch(setShowFooterMenu(result));

  //   return result;
  // };

  async function patientGetInfo() {
    let result = true;

    session.setUserPatient(null);
    session.setCurrentOffice(null);
    store.dispatch(setCurrentOffice(null));

    const userData = session.getUser();

    if (!userData) {
      handleLogout();
    } else {
      const arrPatient = session.getUserPatients();
      for (let i = 0; i < arrPatient.length; i++) {
        let resultGetInfo = await http.patientGetInfo(arrPatient[i]);
        console.log("resultGetInfo", resultGetInfo);

        if (resultGetInfo.status == 200) {
          if (resultGetInfo.data.data) {
            session.setUserPatient(JSON.stringify(resultGetInfo.data.data));

            //--- Asigno el currentOffice cuando sólo tiene un paciente asociado -->
            if (arrPatient.length == 1) {
              if (resultGetInfo.data.data.offices) {
                let auxCurrentOffice = resultGetInfo.data.data.offices[0];
                if (auxCurrentOffice) {
                  store.dispatch(setCurrentOffice(auxCurrentOffice));
                  session.setCurrentOffice(JSON.stringify(auxCurrentOffice));
                }
              }
            }
            //<-- Asigno el currentOffice cuando sólo tiene un paciente asociado ---
          }
        } else {
          handleLogout();
        }
      }
    }

    return result;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Container
          maxWidth="xs"
          className={(classes.noMargin, classes.noPadding)}
        >
          <ThemeProvider
            theme={
              themeCustom
                ? createMuiTheme(themeCustom)
                : createMuiTheme(themeDefault)
            }
          >
            <AuthProvider>
              <div className={classes.root}>
                <CssBaseline />
                  <HeaderTransparent />
                  <Sidebar />
                    <main className={clsx(classes.content, cssAppContent)}>
                      {sidebarOpen ? (
                        <div className={classes.overlay} onClick={handleDrawer} />
                      ) : null}
                      
                      <Routes>
                        <Route exact path="/login" element={<Login />} />
                        <Route path="/recordar-contrasena/:key" element={<Login />} />
                        <Route path="/" element={<Navigate to="/patients" replace />} />
                        <Route path="/patients">
                          <Route index element={<ProtectedRoute><Patients /></ProtectedRoute>} />
                          <Route path=":id/:section" element={<ProtectedRoute><InfoPatient /></ProtectedRoute>} />
                          <Route exact path=":id" element={<ProtectedRoute><InfoPatient /></ProtectedRoute>} />
                        </Route>
                        <Route path="/appointments">
                          <Route index element={<ProtectedRoute><Appointments /></ProtectedRoute>} />
                        </Route>
                        <Route path="/settings">
                          <Route index element={<ProtectedRoute><Settings /></ProtectedRoute>} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </main>
                </div>
                <FooterMenu />
            </AuthProvider>
          </ThemeProvider>
        </Container>
      </BrowserRouter>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;