import { put, call, takeLatest } from 'redux-saga/effects'; 
import { LOGIN_START, LOGIN_ERROR, LOGIN_COMPLETE} from '../../consts/actionTypes';

import { apiCall } from '../api'




export function* loginUser({payload}) {
    try {
        const params = new URLSearchParams();
        params.append('username', payload.username);
        params.append('password', payload.password);
        const header = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }  
        const results = yield call(apiCall, `/api/auth/login`, params, header, 'POST');
        yield put({ type: LOGIN_COMPLETE, results});
    } catch (error) {
        yield put({ type: LOGIN_ERROR, error});
    }
}






export default function* login() {
    yield takeLatest(LOGIN_START, loginUser);
}
