import React from 'react';

export default function KeyValue({className, label, value, unit, classNameLabel = ''}) {
    return(
        <div className={ className ? className : '' }>
            <p className={`text-xs ${ classNameLabel ? classNameLabel : "text-slate-400"}`}>{ label }</p>
            <p className="text-md">{ value } { unit }</p>
        </div>

    );
}