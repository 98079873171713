import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";
// import { setShowFooterMenu } from '../redux/actions/layout';

import { getOffices } from "../redux/actions/offices";
import { cleanInfoPatient } from '../redux/actions/patients';

import { Link, useHistory } from "react-router-dom";

import session from "../utils/session";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: "100%",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },

  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  img: {
    marginTop: "20px",
  },
  width: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  formMargin: {
    paddingBottom: "10px",
  },
}));

const PatientForm = ({
  handleChange,
  editMode,
  handleCloseEdit,
  validateForm,
  selectedDate,
  setSelectedDate,
  requiredFields,
  data,
  button,
  title
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const editButton = useSelector(state => state.patients.editButton);
  const offices = useSelector((state) => state.offices.offices);

  const { professional: professionalData } = session.getUser();
  const professional_id = professionalData.id;

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (!offices) {
      const data = {
        professional_id,
      };
      dispatch(getOffices(data));
    }
    // dispatch(setShowFooterMenu(false));
  }, []);

  const handleClose = () => {
    //setOpen(false);
    //dispatch(setShowHeader(true));
    // dispatch(setShowFooterMenu(true));
    //dispatch(cleanInfoPatient())
    if (editMode) {
      handleCloseEdit();
    } else {
      history.goBack();
    }
    
  };
  
  return (
    <>
      <div className="z-[10000] bg-[#1C124350] absolute top-0 h-screen w-screen font-sans">
        <div style={{borderTopLeftRadius: '40px', borderTopRightRadius: '40px', backgroundColor:'white'}} className="mt-[60px] sm:mt-0 overflow-x-auto">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="flex justify-between px-4 sm:px-0">
                <h3 className="p-4 text-lg text-center font-medium leading-6 text-gray-900"></h3>
                <h3 className="p-4 text-lg text-center font-medium leading-6 text-gray-900">{title}</h3>
                {/* <Link to="/patients"> */}
                <button onClick={handleClose}>

                
                  <h3 className="mt-2 p-4 text-lg text-center font-medium leading-6 text-gray-900">
                    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
                    </svg>              
                  </h3>
                </button> 
                {/* </Link>                  */}
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
                <div className="shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                  <form action="#" method="POST">
                    <div className="grid grid-cols-6 gap-6 overflow-y-auto pb-6" style={{ maxHeight:'calc(100vh - 245px)' }}>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="name" className="block text-base font-medium text-gray-900">
                          <span>Nombres
                            {requiredFields.name && (
                              <span className="ml-1 text-red-600">*</span>
                            )}
                          </span>
                        </label>
                        <input 
                          type="text" 
                          id="name" 
                          className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                          required
                          placeholder="nombres"
                          name="name"
                          value={data.name}
                          onChange={(e) => handleChange(e)}
                          disabled={false}
                        />                          
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="last-name" className="block text-base font-medium text-gray-900">
                          <span>Apellido
                            {requiredFields.surname && (
                              <span className="ml-1 text-red-600">*</span>
                            )}
                          </span>
                        </label>
                        <input 
                          type="text" 
                          id="surname" 
                          className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                          required
                          placeholder="apellido"
                          name="surname"
                          value={data.surname}
                          onChange={(e) => handleChange(e)}
                          disabled={false}

                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-base font-medium text-gray-900">
                        <span>DNI</span>
                      </label>
                      <input 
                        type="number" 
                        id="id_number" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="DNI"
                        name="id_number"
                        value={data.id_number}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-base font-medium text-gray-900">
                        <span>Domicilio</span>
                      </label>
                      <input 
                        type="text" 
                        id="address" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="Domicilio"
                        name="address"
                        value={data.address}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>
            
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-base font-medium text-gray-900">
                        <span>Email</span>
                      </label>
                      <input 
                        type="text" 
                        id="email" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="ejemplo@meducar.com"
                        name="email"
                        value={data.email}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-base font-medium text-gray-900">
                        <span>Teléfono
                          {requiredFields.telephone && (
                            <span className="ml-1 text-red-600">*</span>
                          )}
                        </span>
                      </label>
                      <input 
                        type="number" 
                        id="telephone" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="223555555"
                        name="telephone"
                        value={data.telephone}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <legend className="contents text-base font-medium text-gray-900">Sexo</legend>
                      <div className="mt-4 space-y-4">
                      <div className="flex items-center">
                        <input
                          id="sex"
                          className="text-base block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                          type="radio"
                          name="sex"
                          className="radio"
                          value="m"
                          checked={data.sex === "m"}
                          onChange={(e) => handleChange(e)}
                        />
                        <label htmlFor="sex" className="ml-3 block text-base font-medium text-gray-900">
                          Masculino
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="sex"
                          className="text-base block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                          type="radio"
                          name="sex"
                          className="radio"
                          value="f"
                          checked={data.sex === "f"}
                          onChange={(e) => handleChange(e)}
                        />
                        <label htmlFor="sex" className="ml-3 block text-base font-medium text-gray-900">
                          Femenino
                        </label>
                      </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="office_id" className="block text-base font-medium text-gray-900">
                      <span>Consultorio
                          {requiredFields.office_id && (
                            <span className="ml-1 text-red-600">*</span>
                          )}
                        </span>
                      </label>
                      <select 
                        id="office_id" 
                        name="office_id" 
                        autoComplete="office_id" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        name="office_id"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected value=''>
                          Consultorio
                        </option>
                        {offices
                          ? offices.map((office, index) => (
                          <option key={index} value={office.id}>
                            {office.name}
                          </option>
                        ))
                      : null}
                      </select>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="medical_security" className="block text-base font-medium text-gray-700">
                      <span>Obra Social
                          {requiredFields.office_id && (
                            <span className="ml-1 text-red-600">*</span>
                          )}
                        </span>
                      </label>
                      <select 
                        id="medical_security" 
                        name="medical_security" 
                        autoComplete="medical_security" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        name="medical_security"
                        onChange={(e) => handleChange(e)}
                      >
                        <option disabled selected>
                          Obra Social
                        </option>
                        <option>Medife</option>
                        <option>Agregar</option>
                      </select>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-base font-medium text-gray-900">
                        <span>Número de Afiliado</span>
                      </label>
                      <input 
                        type="number" 
                        id="medical_security_number" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="número de afiliado"
                        nameName="medical_security_number"
                        value={data.medical_security_number}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="current_medication" className="block text-base font-medium text-gray-900">
                        <span>Medicación Actual</span>
                      </label>
                      <input 
                        type="text" 
                        id="current_medication" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="Medicación actual"
                        name="current_medication"
                        value={data.current_medication}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="addictions" className="block text-base font-medium text-gray-900">
                        <span>Adicciones</span>
                      </label>
                      <input 
                        type="text" 
                        id="addictions" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="Adicciones"
                        name="addictions"
                        value={data.addictions}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="pathologies" className="block text-base font-medium text-gray-900">
                        <span>Patologías</span>
                      </label>
                      <input 
                        type="text" 
                        id="pathologies" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="Patologías"
                        name="pathologies"
                        value={data.pathologies}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="history_personal" className="block text-base font-medium text-gray-700">
                        <span>Antecedentes Personales</span>
                      </label>
                      <input 
                        type="text" 
                        id="history_personal" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="Antecedentes Personales"
                        name="history_personal"
                        value={data.history_personal}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="history_familiar" className="block text-base font-medium text-gray-900">
                        <span>Antecedentes Familiares</span>
                      </label>
                      <input 
                        type="text" 
                        id="history_familiar" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="Antecedentes Familiares"
                        name="history_familiar"
                        value={data.history_familiar}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="history_habits" className="block text-base font-medium text-gray-900">
                        <span>Hábitos</span>
                      </label>
                      <input 
                        type="text" 
                        id="history_habits" 
                        className="mt-4 text-base h-12 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                        required
                        placeholder="Hábitos"
                        name="history_habits"
                        value={data.history_habits}
                        onChange={(e) => handleChange(e)}
                        disabled={false}
                      />
                    </div>
                  </div>
                  </form>
                </div>

                <div className="bg-gray-50 px-8 py-3 text-right sm:px-6">
                  <button onClick={(e) => validateForm(e)} type="submit" className="w-full justify-center h-12 rounded-full border border-transparent bg-[#3388FF] py-2 px-4 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">{button}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientForm;
