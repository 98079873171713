import React, { useEffect, useContext, useState, Fragment, useRef } from 'react';

import {useDispatch, useSelector} from 'react-redux';


import { Typography, Button, Container, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';

import EmptyScreen from '../components/EmptyScreen';
import SectionEmpty from '../components/SectionEmpty';
import SectionLoading from '../components/SectionLoading';

import {setPageTitle, setShowHeader} from '../redux/actions/layout';
import {getSchedule, getTags, changeSchemaRangeChange} from '../redux/actions/offices';

import { makeStyles, useTheme  } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import {getFullDate} from '../utils/dates';

import Schedule from '../components/Schedule';
import {getDate} from '../utils/dates';

import session from '../utils/session';

import InfoAppointment from './InfoAppointment';

import 'moment/locale/es';
import Moment from 'react-moment';

import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { cleanInfoPatient } from '../redux/actions/patients';

import TurnosSVG from '../assets/calendar.svg';

import getYears from '../middlewares/getYears';
import convertToDni from '../middlewares/convertToDni';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
        position: 'relative',
        minHeight: 200,
      },
      fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
  }));



 export default function Events() { 

    const dispatch = useDispatch();
 
    const classes = useStyles();
    const pageClasses = useStyles();

    const date = new Date()
    const { professional:professionalData, offices } = session.getUser();
    const professional_id = professionalData.id;

    const schedule = useSelector(state => state.offices.schedule);
    const totalEvents = useSelector(state => state.offices.totalEvents);
    const loading = useSelector(state => state.offices.isLoading);

    const extraDays = useSelector(state => state.offices.date);
    const schemaRange = useSelector(state => state.offices.schemaRange);

    const tags = useSelector(state => state.offices.tags);

    const [ loadCompleted, setLoadCompleted ] = useState(false);
    const [ appointment, setAppointment ] = useState(null);

    const [ eventsTodayPending, setEventsTodayPending ] = useState([]);
    const [ eventsTodayFinished, setEventsTodayFinished ] = useState([]);
    const [ eventsWeekPending, setEventsWeekPending ] = useState([]);
    const [ eventsWeekFinished, setEventsWeekFinished ] = useState([]);

    const elRef = useRef(null);
    const elRef2 = useRef(null);
    const elRefFinalizados = useRef(null);

    useEffect(() => {
      if(!tags) {
        let officeId = 0;
        if (offices) {
          // TOGO_G: Hasta modificar el endpoint ... pido los turnos del primer consultorio
          officeId = offices[0].id;
        }
        dispatch(getTags({officeId: officeId}));
      }
            
    }, []);

    useEffect(() => {
      dispatch(setPageTitle(getTitle()));
      dispatch(setShowHeader(true));
    }, [totalEvents]);

    useEffect(() => {
      if (!loading && !loadCompleted) {
        setLoadCompleted(true);
      }
    }, [loading]);

    useEffect(() => {
      const today = moment().format('YYYY-MM-DD');

      let auxEvTodayPend = [];
      let auxEvTodayFin = [];
      let auxEvWeekPend = [];
      let auxEvWeekFin = [];

      if (schedule) {
        schedule.map((el, index) => {
          if (moment(el.date_from, 'YYYY-MM-DD hh-mm-ss').format('YYYY-MM-DD') == today) {
            if (el.state >= 50) {
              auxEvTodayFin.push(el);
            } else {
              auxEvTodayPend.push(el);
              auxEvWeekPend.push(el);
            }            
          } else {
            if (el.state >= 50) {
              auxEvWeekFin.push(el);
            } else {
              auxEvWeekPend.push(el);
            }            
          }                               
        });
      }

      setEventsTodayPending(auxEvTodayPend);
      setEventsTodayFinished(auxEvTodayFin);
      setEventsWeekPending(auxEvWeekPend);
      setEventsWeekFinished(auxEvWeekFin);

    }, [schedule]);

    //TODO_G: BORRAR
    // useEffect(() => {
    //   //office id, deberia venir de session (actualmente no se almacena ninguno en session)
    //   dispatch(setPageTitle('Turnos'));
    //   getAllEvents();
      
    // }, [extraDays]);

    useEffect(() => {

      if (loading) return;
      

      // Si la vista es la del día
      if (schemaRange.type == 'today') {
        if ((eventsTodayPending.length == 0) && (eventsTodayFinished.length > 0)) {
          if (!elRefFinalizados.current) return;
          if (!elRefFinalizados.current.checked) {
            elRefFinalizados.current.click();
          }
        } else {
          if (eventsTodayPending.length > 0) {
            if (elRef.current) {
             if (!elRef.current.checked) {
                elRef.current.click();
             }
            }          
          }
        }          
      }

      // Si la vista es la de la semana
      if (schemaRange.type == 'week') {
        if ((eventsWeekPending.length == 0) && ((eventsTodayFinished.length > 0) || (eventsWeekFinished.length > 0))) {            
          if (!elRefFinalizados.current) return;
          if (!elRefFinalizados.current.checked) {
            elRefFinalizados.current.click();
          }
        } else {
          if (eventsWeekPending.length > 0) {
            if (elRef2.current) {
              if (!elRef2.current.checked) {
                elRef2.current.click();
              }
            }          
          }
        }        
      }

    }, [elRef.current, elRef2.current, elRefFinalizados.current, eventsTodayPending, eventsTodayFinished, eventsWeekPending, eventsWeekFinished]);


    useEffect(() => {
      //office id, deberia venir de session (actualmente no se almacena ninguno en session)
      // dispatch(setPageTitle(getTitle()));
      getAllEvents();
      
    }, [schemaRange]);
    


    //  const returnDay = (date) => {
    //   let formattedDate = moment(date, "YYYY-MM-DD hh:mm:ss").format("L");
    //   console.log(formattedDate, days)
    //   console.log(days.indexOf(formattedDate))
    //   if (days.indexOf(formattedDate) == -1) {
    //     setDays([...days, formattedDate]);
    //     console.log('SI')
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    //  }

     const getAllEvents = () => {

      // console.log(offices);
      let officeId = 0;
      if (offices) {
        // TOGO_G: Hasta modificar el endpoint ... pido los turnos del primer consultorio
        officeId = offices[0].id;
      }

      const data = {
        officeId: officeId,
        professional_id,
        initialDate: (schemaRange.init ? schemaRange.init : getDate()),
        lastDate: (schemaRange.finish ? schemaRange.finish : ((schemaRange.type == 'today') ? getDate() : getDate(7)))
      }
      dispatch(getSchedule(data));
    }

    const getTitle = () => {
      return (
        <div className="font-sans">
          Turnos
          {totalEvents > 0 && (
            <span>{` (${totalEvents})`}</span>
          )}
        </div>
      );
    };

    const onChangeRange = (type) => {
      const aux = {
        type: type,
        init: getDate(),
        finish: ((type == 'today') ? getDate() : getDate(7))
      }
      // setCurrentRange(type);
      dispatch(changeSchemaRangeChange(aux));
    }

    const handleClick = (e, appointment) => {
      // setAppointment(appointment);
      // dispatch(setShowHeader(false));
    }


     const handleCloseAppointment = () => {
      setAppointment(null);
      dispatch(setShowHeader(true));
      dispatch(cleanInfoPatient())
      getAllEvents()
    }

    const showStatus = (event_state, hour = null) => {
      if (hour) {
        hour = moment(hour, "YYYY-MM-DD hh-mm-ss").format("HH:mm")
      }
      return (
        <div className={`font-sans text-[${event_state.color}] font-semibold`}>
          <div className="inline ml-1">{ hour } hs</div>
          <div className={`badge inline ml-1 bg-[${event_state.color}]`}>
            { event_state.value }
          </div>
        </div>        
      )
    }

    const showItem = (el, index) => {
      let patientName = '';
      if (el.patient) {
        patientName = el.patient.name + ' ' + el.patient.surname;
      } else {
        patientName = el.patient_name;
      }




      return(
        <>
          {/* 
          <div className="w-full text-center text-lg font-bold flex flex-col">
            {
              index > 0 && moment(schedule[index-1].date_from, "YYYY-MM-DD hh-mm-ss").format("DD-MM-YYYY") == moment(schedule[index].date_from, "YYYY-MM-DD hh-mm-ss").format("DD-MM-YYYY") 
              ? 
                null 
              : 
                <Moment date={el.date_from} format="LL" locale="es" />
            }
          </div> 
          */}

          <div className='font-sans bg-white p-1 border shadow-lg rounded-lg p-3 mb-3'>
            <div
              className="flex justify-between items-center"
              onClick={(e) => handleClick(e, el)}
            >
              <div className='flex flex-col w-full'>
                    
                <div className="flex w-full">
                  {el.patient?.photo ? (
                    // <img src="https://i.imgur.com/aq39RMA.jpg" width="50" height="50" className="rounded-full" />
                    <img
                      src={`https://cdn.meducar.com/small/${el.patient.photo}`}
                      width="50"
                      height="50"
                      className="rounded-full max-h-50px mt-2"
                      onError={(event) => {
                        event.target.src =
                          "https://cdn.meducar.com/img/patient_notfound.png";
                        event.onerror = null;
                      }}
                      alt={el.patient.photo}
                      style={{ maxHeight: 50 }}
                    />
                  ) : (
                    <>
                    <div className="flex flex-col">
                      <Avatar className="mt-1" name={ patientName } size="50" round={true} color="rgba(194, 165, 62, 1)" />
                    </div>
                    </>
                  )}

                  <div className="flex flex-col ml-2">

                    <span className="text-lg font-bold text-black truncate w-full">
                      { patientName }
                    </span>
                    <span className="text-sm text-black truncate w-full">
                      <div className='inline'>
                        {el.patient?.id_number && (`DNI: ${convertToDni(el.patient?.id_number)}`)}
                        {el.patient_id_number && (`DNI: ${convertToDni(el.patient_id_number)}`)}
                      </div>
                      <div className='inline mx-1'>/</div>
                      <div className='inline'>
                        {el.patient?.born_date && (getYears(el.patient?.born_date))}
                        {el.patient_born_date && (getYears(el.patient_born_date))}
                      </div>                    
                    </span>
                    <div>
                      <span className="text-sm text-gray-400 truncate w-full">
                        Consulta con id: {el.consultation_type_id}
                      </span>               
                    </div>
                  </div>                
                </div>

                <div className='border-t border-gray-300 mt-2 pt-4'>
                  <div className='flex justify-between items-center'>

                    <div>
                      <img src={ TurnosSVG } className="inline mr-1 -mt-1" />
                      <div className='inline text-black font-semibold pt-2'>
                        { moment(el.date_from, "YYYY-MM-DD hh-mm-ss").format("DD/MM/YYYY") }
                      </div>                    
                    </div>

                    <div>
                      { showStatus(el.state_color, el.date_from) }
                    </div>

                  </div>
                </div>

              </div>    
            </div>

            {el.tags && el.tags?.length > 0 && (
              <div className="flex text-sm text-center pt-2 pl-1">
                {el.tags.map((element) => (
                  <div
                    style={{
                      backgroundColor: '#A29EB6', // element.tag.color,
                      border: "none",
                    }}
                    key={element.tag.id}
                    className="mr-1 mb-1 badge"
                  >
                    {element.tag.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )
    }

     return (
       <>
        <div className="py-20 h-screen bg-white px-2">
          <div className="font-sans flex justify-between pb-4 px-2">
            <div>
              <button className={`${schemaRange.type == 'today' ? 'bg-[#3388FF] text-white' : 'text-gray-400'} font-semibold text-lg w-24 h-12 mx-1 rounded-lg`} onClick={ () => onChangeRange('today') }>Día</button>
              <button className={`${schemaRange.type == 'week' ? 'bg-[#3388FF] text-white' : 'text-gray-400'} font-semibold text-lg w-24 h-12 mx-1 rounded-lg`} onClick={ () => onChangeRange('week') }>Semana</button>
            </div>
            {/* <div className="flex-none ml-2">
              <Link to="/new-appointment">
                <button className="bg-[#3388FF] text-white text-lg w-12 h-12 rounded-lg">+</button>
              </Link>
            </div> */}
          </div>

          {
            (loadCompleted ) && (
              <div className="max-h-full max-w-md mx-auto bg-white overflow-scroll md:max-w-lg">
                <div className="md:flex">
                  <div className="w-full p-0">                    
                    
                    {/* 
                    <div className="relative">
                      {!loading && totalEvents > 0 && (
                        <div className="flex justify-center border-b-2 border-black">
                          <div className="flex mt-2 text-gray-400">
                            {`${totalEvents} resultado${
                              totalEvents > 1 ? "s" : ""
                            } para la búsqueda`}
                          </div>
                        </div>
                      )}
                    </div> 
                    */}
                    {/* <div className="bg-gray-200 text-center h-16">AQUI VA EL SELECTOR DE FECHA</div> */}
                    

                    { /*  
                      SI NO TIENE TURNOS: "<i className="fa fa-thumbs-o-up text-success"></i> Ya no tienes más pacientes en el día"
                      SI NO TUVO TURNOS: "No tienes pacientes agendados para hoy..."
                    */ }
                    { schemaRange.type == "today" ?

                      !loading && eventsTodayPending.length < 1 ? (
                        <SectionEmpty 
                          show 
                          title={ 'Estás Libre!' } 
                          subtitle={` ${ eventsTodayFinished.length > 0 ? 'Ya no tienes más pacientes en el día' : 'No tienes pacientes agendados para hoy...' } `} 
                          fullscreen={ false }
                        />
                      ) : (
                          <div className="mt-4">
                          <div className="collapse collapse-arrow">
                            <input ref={el => {elRef.current = el}} type="checkbox" className="peer" /> 
                            <div className="font-sans collapse-title text-lg font-normal peer-checked:font-bold peer-checked:text-lg">
                              Próximos turnos
                            </div>
                            <div className="collapse-content p-0"> 
                              {
                                eventsTodayPending && (
                                  eventsTodayPending.map((el, index) => (
                                    <div key={`events-key-pending-${index}`}>
                                      { showItem(el, index) }
                                    </div>                        
                                  ))
                                )
                              }
                            </div>
                          </div>
                        </div>
                        
                      )
                     : 
                     
                     !loading && eventsWeekPending.length < 1 ? (
                      <SectionEmpty show title={ 'No hay turnos' } subtitle={ `No tienes turnos tomados hasta el ${getFullDate(extraDays)}` } fullscreen={ false } />
                      ) : (
                          <div className="mt-4">
                          <div className="collapse collapse-arrow">
                            <input ref={el => {elRef2.current = el}} type="checkbox" className="peer" /> 
                            <div className="font-sans collapse-title text-lg font-normal peer-checked:font-bold peer-checked:text-lg">
                              Próximos turnos
                            </div>
                            <div className="collapse-content p-0"> 
                              {
                                eventsWeekPending && (
                                  eventsWeekPending.map((el, index) => (
                                    <div key={`events-key-pending-${index}`}>
                                      { showItem(el, index) }
                                    </div>                        
                                  ))
                                )
                              }
                            </div>
                          </div>
                        </div>
                      )
                    }
                    

                  { schemaRange.type == "today" &&
                    (eventsTodayFinished.length > 0 || eventsWeekFinished.length > 0 ? (
                      <div>
                        <div className="collapse collapse-arrow">
                          <input ref={el => {elRefFinalizados.current = el}} type="checkbox" className="peer" /> 
                          <div className="font-sans collapse-title text-lg font-normal peer-checked:font-bold peer-checked:text-lg">
                            Turnos finalizados
                          </div>
                          <div className="collapse-content p-0"> 
                            {
                              eventsTodayFinished && (
                                eventsTodayFinished.map((el, index) => (
                                  <div key={`events-key-finished-${index}`}>
                                    { showItem(el, index) }
                                  </div>                        
                                ))
                              )
                            }
                            {
                              eventsWeekFinished && (
                                eventsWeekFinished.map((el, index) => (
                                  <div key={`events-key-finished-${index}`}>
                                    { showItem(el, index) }
                                  </div>                        
                                ))
                              )
                            }
                          </div>
                        </div>
                      </div>
                    
                    ) : (
                      <div></div>                      
                    ))
                  }  
                    

                    {/* 
                    <div>
                      {
                        schedule && (
                          schedule.map((el, index) => (
                            <div key={`events-key-${index}`}>
                              { showItem(el, index) }
                            </div>                        
                          ))
                        )
                      }
                    </div> 
                    */}
                    
                  </div>
                </div>
              </div>
            )
          }

          <SectionLoading loading={ loading } smaller={ true }/>
          {/* { !loadCompleted && <SectionLoading loading={ loading } smaller={ true }/> } */}
          
        </div>
        
        {/* <SectionLoading loading={ loading } smaller={ true }/> */}
        {/* <SectionEmpty show={ (loadCompleted && !schedule) } title={ 'No hay turnos' } subtitle={ `No tienes turnos tomados hasta el ${getFullDate(extraDays)}` } fullscreen={ false } /> */}
       
        {/* {!schedule && (
          <>
            
            <EmptyScreen
              title="No hay turnos"
              msgOpt="¡Estas Libre!"
              msg={`No tienes turnos tomados hasta el ${getFullDate(
                extraDays
              )}`}
              Icon={AccessTimeIcon}
            />
          </>
        )} */}

         {
          appointment && (
           <div
             className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
             id="my-modal"
           >
             <InfoAppointment
               appointment={appointment}
               handleCloseAppointment={handleCloseAppointment}
             />
           </div>
          )
         }
         {
          /*
           : (
           <Link to="/new-appointment" className="link">
             <Fab color="primary" aria-label="add" className={pageClasses.fab}>
               <AddIcon />
             </Fab>
           </Link>
         )}
          */
         }
       </>
     );
 }