import { put, call, takeLatest } from 'redux-saga/effects'; 
import { 
    GET_PRESCRIPTIONS_COMPLETE,
    GET_PRESCRIPTIONS_ERROR, 
    GET_PRESCRIPTIONS_START,
    CREATE_NEW_PRESCRIPTION_COMPLETE,
    CREATE_NEW_PRESCRIPTION_ERROR,
    CREATE_NEW_PRESCRIPTION_START
} 
from '../../consts/actionTypes';

import { apiCall } from '../api';





export function* getPrescriptions({payload}) {
    try {
        const results = yield call(apiCall, `/api/professionals/${payload.professional_id}/prescriptions?search=${payload.name}&limit=${payload.limit}&page=${payload.pageNumber}`, null, null, 'GET');
        yield put({ type: GET_PRESCRIPTIONS_COMPLETE, results, name: payload.name, reset: payload.reset});
    } catch (error) {
        yield put({ type: GET_PRESCRIPTIONS_ERROR, error});
    }
}

export function* createPrescription({payload}) {
    try {
        console.log(payload)
        const results = yield call(apiCall, `/api/professionals/16873/patients/2106/prescriptions`, payload, null, 'POST');
        console.log(results);
        if (results.status == 200) {
            yield put({ type: CREATE_NEW_PRESCRIPTION_COMPLETE, results});
        } else {
            yield put({ type: CREATE_NEW_PRESCRIPTION_ERROR, results});
        }        

    } catch (error) {
        yield put({ type: CREATE_NEW_PRESCRIPTION_ERROR, error});
    }
}



export default function* prescriptions() {
    yield takeLatest(GET_PRESCRIPTIONS_START, getPrescriptions);
    yield takeLatest(CREATE_NEW_PRESCRIPTION_START, createPrescription);
}

