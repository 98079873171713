import { put, call, takeLatest } from 'redux-saga/effects'; 
import { CREATE_NEW_PATIENT_COMPLETE, CREATE_NEW_PATIENT_ERROR, CREATE_NEW_PATIENT_START, GET_HC_COMPLETE, GET_HC_ERROR, GET_HC_START, GET_INFO_PATIENT_COMPLETE,
        CLEAN_INFO_PATIENT,
        GET_INFO_PATIENT_ERROR,
        GET_INFO_PATIENT_START,
        GET_PATIENTS_COMPLETE,
        GET_PATIENTS_ERROR, 
        GET_PATIENTS_START,
        UPDATE_PATIENT_COMPLETE,
        UPDATE_PATIENT_ERROR,
        UPDATE_PATIENT_START,
        CHANGE_PATIENT_TAGS_START,
        CHANGE_PATIENT_TAGS_ERROR,
        CHANGE_PATIENT_TAGS_COMPLETE,
} 
from '../../consts/actionTypes';

import { apiCall } from '../api';





export function* getPatients({payload}) {
    try {
        const results = yield call(apiCall, `/api/professionals/${payload.professional_id}/patients?search=${payload.name}&limit=${payload.limit}&page=${payload.pageNumber}`, null, null, 'GET');
        yield put({ type: GET_PATIENTS_COMPLETE, results, name: payload.name, reset: payload.reset});
    } catch (error) {
        yield put({ type: GET_PATIENTS_ERROR, error});
    }
}

export function* createPatient({payload}) {
    try {
        console.log(payload)
        const results = yield call(apiCall, `/api/professionals/${payload.professional_id}/patients`, payload.patient, null, 'POST');
        console.log(results);
        if (results.status == 200) {
            yield put({ type: CREATE_NEW_PATIENT_COMPLETE, results});
        } else {
            yield put({ type: CREATE_NEW_PATIENT_ERROR, results});
        }        

    } catch (error) {
        yield put({ type: CREATE_NEW_PATIENT_ERROR, error});
    }
}


export function* updatePatient({payload}) {
    try {
        const results = yield call(apiCall, `/api/professionals/${payload.professional_id}/patients/${payload.patient_id}`, payload.newData, null, 'PATCH');
        yield put({ type: UPDATE_PATIENT_COMPLETE, results});
    } catch (error) {
        yield put({ type: UPDATE_PATIENT_ERROR, error})
    }
}


export function* getPatientInfo({payload}) {
    try {

        const results = yield call(apiCall, `/api/professionals/${payload.professional_id}/patients/${payload.id}`, null, null, 'GET');

        yield put({ type: GET_INFO_PATIENT_COMPLETE, results});     
    } catch(error) {
        yield put({ type: GET_INFO_PATIENT_ERROR, error})
    }
}

export function* cleanPatientInfo({payload}) { //TODO_N: Revisar Esto
    try {

        yield put({ type: CLEAN_INFO_PATIENT });     
    } catch(error) {
        yield put({ type: GET_INFO_PATIENT_ERROR, error})
    }
}

export function* getPatientHC({payload}) {
    try {
        const results = yield call(apiCall, `/api/professionals/${payload.professional_id}/patients/${payload.id}/hc`, null, null, 'GET');

        yield put({type: GET_HC_COMPLETE, results});
    } catch (error) {
        yield put({ type: GET_HC_ERROR, error})
    }
}

export function* changePatientTags({payload}) {
    try {
        console.log(payload);
        let formData = new FormData;
        formData.append('type', payload.type);
        formData.append('tag_id', payload.tagId);
        formData.append('operation', payload.operation);
        formData.append(`${payload.type}_id`, payload.typeValue);

        const results = yield call(apiCall, `/api/offices/${payload.officeId}/tags`, formData, null, 'POST');

        yield put({ type: CHANGE_PATIENT_TAGS_COMPLETE, results});

    } catch(error) {
        console.log(error);
        yield put({type: CHANGE_PATIENT_TAGS_ERROR});
    }
}



export default function* patients() {
    yield takeLatest(GET_PATIENTS_START, getPatients);
    yield takeLatest(CREATE_NEW_PATIENT_START, createPatient);
    yield takeLatest(GET_INFO_PATIENT_START, getPatientInfo);
    yield takeLatest(UPDATE_PATIENT_START, updatePatient);
    yield takeLatest(GET_HC_START, getPatientHC);
    yield takeLatest(CHANGE_PATIENT_TAGS_START, changePatientTags);
}
