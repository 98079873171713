import moment from "moment";
import convertToDni from '../middlewares/convertToDni';

const ProfilePatient = ({ patient }) => {
  const tr = 'min-h-[36px]';
  const colLeft = 'w-[40%] text-lg font-semibold text-[#3388FF] py-2';
  const colRight = 'w-[60%] text-lg font-semibold text-black leading-none py-2';

  return (
    <>
      <main style={{backgroundColor:'#FFFFFF'}} className="py-4 px-2 sm:p-4 md:py-10 md:px-8">
        <div className="max-w-4xl mx-auto grid grid-cols-1 lg:max-w-5xl lg:gap-x-20 lg:grid-cols-2">
          <div className="mt-4 col-start-1 row-start-3 self-center sm:mt-0 sm:col-start-2 sm:row-start-2 sm:row-span-2 lg:mt-6 lg:col-start-1 lg:row-start-3 lg:row-end-4">
            <table className="font-sans w-full" style={{ /* marginLeft:'33px', marginBottom:'10px' */ }}>
              <tbody>
                  <tr className={ tr }>
                    <td className={`${colLeft} font-extrabold`}>Consultorio</td>
                    <td className={`${colRight} font-extrabold`}>{ patient.offices[0] ? patient.offices[0].name : '--' }</td>
                  </tr>
                  <tr style={{textAlign:'left', height:'36px'}}>
                    <td className={ colLeft }>Nombre</td>            
                    <td className={ colRight }>{ patient.name ? patient.name : '--' }</td>
                  </tr>
                  <tr style={{textAlign:'left', height:'36px'}}>
                    <td className={ colLeft }>Apellido</td>
                    <td className={ colRight }>{ patient.surname ? patient.surname : '--' }</td>
                  </tr>
                  <tr style={{textAlign:'left', height:'36px'}}>
                    <td className={ colLeft }>DNI</td>            
                    <td className={ colRight }>{ patient.id_number ? convertToDni(patient.id_number) : '--' }</td>
                  </tr>
                  <tr style={{textAlign:'left', height:'36px'}}>
                    <td className={ colLeft }>Teléfono</td>
                    <td className={ colRight }>{ patient.telephone ? patient.telephone : '--' }</td>
                  </tr>
                  <tr style={{textAlign:'left', height:'36px'}}>
                    <td className={ colLeft }>Domicilio</td>            
                    <td className={ colRight }>{ patient.address ? patient.address : '--' }</td>
                  </tr>
                  <tr style={{textAlign:'left', height:'36px'}}>
                    <td className={ colLeft }>Email</td>
                    <td className={ colRight }>{ patient.email ? patient.email : '--' }</td>
                  </tr>
                  <tr style={{textAlign:'left', height:'36px'}}>
                    <td className={ colLeft }>Fecha de Nac.</td>            
                    <td className={ colRight }>{ patient.born_date ? moment(patient.born_date, 'YYYY-MM-DD').format('DD/MM/YYYY') : '--' }</td>
                  </tr>
                </tbody>     
            </table>
          </div>
        </div>
      </main>
    </>
  )
}

export default ProfilePatient;