import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { Default, General, Odontology, Pediatrics } from "./Consultations";
import KeyValue from "./_Utils/KeyValue";
import { useNavigate } from "react-router-dom";

const HcDetail = ({ data }) => {
  const navigate = useNavigate();

  
  console.log(data)

  /*
  const selectTypeEvent = (el) => {

    switch(data.consultation_type_id) {
      case 1055:
          return <General />
          break;
      case 10:
          return <Pediatrics />
          break;
      case 6377:
        return <Odontology />
        break;   
      default:
          return <Default />
    }
  }
  */

  const headerTitle = useMemo(() => {
    let result = '';
    switch (data.evolution_type) {
      case 'consultation':
        result = 'Consulta del ';
        break;
      case 'image':
        result = 'Imagen del ';
        break;
    }

    return (
      <>
        { result }<Moment format="DD-MM-YYYY">{data.date_from}</Moment>
      </>      
    );

  }, [data.evolution_type])

  const showHeader = () => {
    return(
      <div className="md:col-span-1 border-b">
        <div className="flex justify-between px-4 sm:px-0">
          <h3 className="p-4 text-xl text-center font-medium leading-6 text-gray-900"></h3>
          <h3 className="p-4 text-xl text-center font-medium leading-6 text-gray-900">
            { headerTitle }            
          </h3>
          <button onClick={() => navigate(-1)} >                
            <h3 className="mt-2 p-4 text-xl text-center font-medium leading-6 text-gray-900">
              <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.04289 0.292893C1.43342 -0.0976311 2.06658 -0.0976311 2.45711 0.292893L5.75 3.58579L9.04289 0.292893C9.43342 -0.0976309 10.0666 -0.0976309 10.4571 0.292893C10.8476 0.683418 10.8476 1.31658 10.4571 1.70711L7.16421 5L10.4571 8.29289C10.8476 8.68342 10.8476 9.31658 10.4571 9.70711C10.0666 10.0976 9.43342 10.0976 9.04289 9.70711L5.75 6.41421L2.45711 9.70711C2.06658 10.0976 1.43342 10.0976 1.04289 9.70711C0.652369 9.31658 0.652369 8.68342 1.04289 8.29289L4.33579 5L1.04289 1.70711C0.652369 1.31658 0.652369 0.683418 1.04289 0.292893Z" fill="black"/>
              </svg>              
            </h3>
          </button> 
        </div>
      </div>
    )
  }

  return (
    <div className="z-[10000] absolute top-0 h-screen w-screen font-sans" >
      <div className="bg-gray-300 top-0 h-screen w-screen" style={{ opacity: 1 }}></div>
      <div style={{borderTopLeftRadius: '40px', borderTopRightRadius: '40px', backgroundColor:'white' }} className="mt-[60px] bg-white sm:mt-0 h-screen overflow-x-auto fixed">
        <div className="md:grid md:grid-cols-3 md:gap-6 w-screen h-screen">

          <div style={{borderTopLeftRadius: '40px', borderTopRightRadius: '40px'}} className="fixed top-[44px] w-screen h-screen bg-white pb-4">        
          
            { showHeader() } 

            { 
              (data.evolution_type == 'consultation') && (
                <>
                  <div className="mx-2 p-2">
                    <div className="text-lg font-semibold text-center uppercase">{data.data_struct.name}</div>
                    <div className="text-sm font-semibold text-center">Hora <b><Moment format="HH:mm">{data.date_from}</Moment> hs.</b> / Duración <b>{data.duration} min</b></div>
                    <div className="text-xs font-slate-400 text-center">{data.office_name}</div>
                  </div>

                  <div className="mx-2 mt-2 p-2">
                    <KeyValue className="mb-2 text-lg" label="Motivo de consulta" value={ data.data.eventObservations } />
                    {/* <KeyValue className="mb-2 text-lg" label="Tipo de consulta" value={ data.data_struct.name } /> */}
                  
                    <hr className="mb-4"></hr>

                    <Default struct={ data.data_struct } data={ data.data } />

                    {
                      data.private_notes && (
                        <KeyValue className="mt-4 mb-2 text-lg" classNameLabel="text-[#26A69A]" label="Notas Personales" value={ data.private_notes } />
                      )
                    }
                  </div>

                  {/* <div className="ml-8 mr-8 mt-10 grid grid-cols-2 rounded-xl shadow-md p-4">
                    <div className="ml-8 font-semibold text-sm text-gray-400 leading-5">
                      <div>Fecha</div>
                      <div>Nombre Paciente</div>
                      <div>Tipo Evento</div>
                      <div>Profesional</div>
                    </div>
                    <div className="mr-8 font-semibold text-sm leading-5">
                      <div>{data.date_from}</div>
                      <div>Paciente {data.patient_id}</div>
                      <div>{data.data_struct.name}</div>
                      <div>Dr. {data.professional_id}</div>
                    </div>
                  </div> */}                
                </>                 
              )        
            }

            {
              (data.evolution_type == 'image') && (
                <div className="p-2">
                  <div className="flex flex-col">
                    <img className="w-full" src={ `https://cdn.meducar.com/small/${data.image}` } />
                    <hr className="my-2"></hr>
                    <div className="px-2">
                      <KeyValue label="Descripción" value={ data.desc } />
                    </div>
                  </div>
                  
                </div>
                
              )
            }

            </div>
    
        </div>
      </div>
    </div>
  );
};

export default HcDetail;
