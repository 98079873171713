export const GET_PATIENTS_START = 'GET_PATIENTS_START';
export const GET_PATIENTS_ERROR = 'GET_PATIENTS_ERROR';
export const GET_PATIENTS_COMPLETE = 'GET_PATIENTS_COMPLETE';

export const GET_HC_START = 'GET_HC_START';
export const GET_HC_ERROR = 'GET_HC_ERROR';
export const GET_HC_COMPLETE = 'GET_HC_COMPLETE';


export const CREATE_NEW_PATIENT_START = 'CREATE_NEW_PATIENT_START';
export const CREATE_NEW_PATIENT_ERROR = 'CREATE_NEW_PATIENT_ERROR';
export const CREATE_NEW_PATIENT_COMPLETE = 'CREATE_NEW_PATIENT_COMPLETE';

export const UPDATE_PATIENT_START = 'UPDATE_PATIENT_START';
export const UPDATE_PATIENT_ERROR = 'UPDATE_PATIENT_ERROR';
export const UPDATE_PATIENT_COMPLETE = 'UPDATE_PATIENT_COMPLETE';

export const GET_INFO_PATIENT_START = 'GET_INFO_PATIENT_START';
export const GET_INFO_PATIENT_ERROR = 'GET_INFO_PATIENT_ERROR';
export const GET_INFO_PATIENT_COMPLETE = 'GET_INFO_PATIENT_COMPLETE';
export const CLEAN_INFO_PATIENT = 'CLEAN_INFO_PATIENT';

export const CLEAN_PATIENTS = 'CLEAN_PATIENTS';

export const USE_EDIT_BUTTON = 'USE_EDIT_BUTTON';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_ERROR = 'LOGIN_ERROR'; 
export const LOGIN_COMPLETE = 'LOGIN_COMPLETE';

export const LOGOUT = 'LOGOUT'; 

export const SAVE_USERDATA = 'SAVE_USERDATA';

export const UPDATE_TOKEN = 'UPDATE_TOKEN';


export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export const SET_BACK_BUTTON = 'SET_BACK_BUTTON';

export const SET_SHOW_HEADER = 'SET_SHOW_HEADER';
export const SHOW_FOOTER_MENU = 'SHOW_FOOTER_MENU';

export const OPEN_SIDEBAR_START = 'OPEN_SIDEBAR_START';
export const OPEN_SIDEBAR_COMPLETE = 'OPEN_SIDEBAR_COMPLETE';

export const GET_OFFICES_START = 'GET_OFFICES_START';
export const GET_OFFICES_ERROR = 'GET_OFFICES_ERROR';
export const GET_OFFICES_COMPLETE = 'GET_OFFICES_COMPLETE';

export const SET_CURRENT_OFFICE = 'SET_CURRENT_OFFICE';

export const GET_EVENT_OPTIONS_START = 'GET_EVENT_OPTIONS_START';
export const GET_EVENT_OPTIONS_ERROR = 'GET_EVENT_OPTIONS_ERROR';
export const GET_EVENT_OPTIONS_COMPLETE = 'GET_EVENT_OPTIONS_COMPLETE';

export const GET_SCHEDULE_START = 'GET_SCHEDULE_START'
export const GET_SCHEDULE_ERROR = 'GET_SCHEDULE_ERROR';
export const GET_SCHEDULE_COMPLETE = 'GET_SCHEDULE_COMPLETE';

export const APPOINTMENT_DATE = 'APPOINTMENT_DATE';
export const SCHEDULE_RANGE_CHANGE = 'SCHEDULE_RANGE_CHANGE';

export const CREATE_APPOINTMENT_START = 'CREATE_APPOINTMENT_START';
export const CREATE_APPOINTMENT_ERROR = 'CREATE_APPOINTMENT_ERROR';
export const CREATE_APPOINTMENT_COMPLETE = 'CREATE_APPOINTMENT_COMPLETE';

export const RESET_APPOINTMENT_OPTIONS = 'RESET_APPOINTMENT_OPTIONS';


export const NEW_APPOINTMENT_START = 'NEW_APPOINTMENT_START';
export const NEW_APPOINTMENT_ERROR = 'NEW_APPOINTMENT_ERROR';
export const NEW_APPOINTMENT_COMPLETE = 'NEW_APPOINTMENT_COMPLETE';

export const GET_TAGS_START = 'GET_TAGS_START';
export const GET_TAGS_ERROR = 'GET_TAGS_ERROR';
export const GET_TAGS_COMPLETE = 'GET_TAGS_COMPLETE';

export const CHANGE_TAGS_START = 'CHANGE_TAGS_START';
export const CHANGE_TAGS_ERROR = 'CHANGE_TAGS_ERROR';
export const CHANGE_TAGS_COMPLETE = 'CHANGE_TAGS_COMPLETE';

export const CREATE_NEW_PRESCRIPTION_START = 'CREATE_NEW_PRESCRIPTION_START';
export const CREATE_NEW_PRESCRIPTION_ERROR = 'CREATE_NEW_PRESCRIPTION_ERROR';
export const CREATE_NEW_PRESCRIPTION_COMPLETE = 'CREATE_NEW_PRESCRIPTION_COMPLETE';

export const GET_PRESCRIPTIONS_START = 'GET_PRESCRIPTIONS_START';
export const GET_PRESCRIPTIONS_ERROR = 'GET_PRESCRIPTIONS_ERROR';
export const GET_PRESCRIPTIONS_COMPLETE = 'GET_PRESCRIPTIONS_COMPLETE';

export const CHANGE_PATIENT_TAGS_START = 'CHANGE_PATIENT_TAGS_START';
export const CHANGE_PATIENT_TAGS_ERROR = 'CHANGE_PATIENT_TAGS_ERROR';
export const CHANGE_PATIENT_TAGS_COMPLETE = 'CHANGE_PATIENT_TAGS_COMPLETE';