import React, { useEffect, useState, useMemo } from 'react';
import clsx from "clsx";

import { useSelector } from 'react-redux';

import { useStylesApp } from "../appStyles";

import { Link, useLocation } from 'react-router-dom';

import PatientsSVG from '../assets/users.svg';
import TurnosSVG from '../assets/calendar.svg';
import PrescriptionSVG from '../assets/recetas.svg';
import PerfilSVG from '../assets/config.svg';

export default function FooterMenu() {
    const location = useLocation();

    // const currentPage = useSelector(state => state.layout.currentPage);
    // const showFooterMenu = useSelector(state => state.layout.showFooterMenu);

    const classes = useStylesApp();

    const classMenuItemSVG = 'flex justify-center align w-[36px] h-[36px] my-[-10px] mx-[-15px] p-[6px] rounded-full text-center';
    const classMenuItems = 'flex-col px-2 mt-1 font-medium';

    const actualPath = useMemo(() => {
        let path = location.pathname;
        if (path.substr(-1) === "/") {
            path = path.slice(0, -1)
        }
        return path
    }, [location])

    const showFooterMenu = useMemo(() => {
        return actualPath === "/patients" || actualPath === "/appointments" || actualPath === "/settings"
    }, [actualPath])
 
    return(
        <>
            {
                (showFooterMenu) && (
                    <div className={clsx(classes.footer, 'text-center')}>
                        
                        <div className="bg-[#EBF1FF] h-[2px] mx-6 rounded-full">&nbsp;</div>
                        
                        <ul className="menu px-3 bg-white horizontal font-sans">
                            <li>
                                <Link to="/patients" className={ clsx(classMenuItems, (actualPath === '/patients') && 'current') }>
                                <div className={ clsx(classMenuItemSVG, (actualPath === '/patients') ? 'bg-[#3388FF] filter-invert-img' : '') }>
                                    <img src={ PatientsSVG } alt="Pacientes" />
                                </div>                                
                                Pacientes
                                </Link>                
                            </li>
                            <li>
                                <Link to="/appointments" className={ clsx(classMenuItems, (actualPath === '/appointments') && 'current') }>
                                <div className={ clsx(classMenuItemSVG, (actualPath === '/appointments') ? 'bg-[#3388FF] filter-invert-img' : '') }>
                                    <img src={ TurnosSVG } alt="Turnos" />
                                </div>                                
                                Turnos
                                </Link>                
                            </li>
                            { /*
                            <li>
                                <Link to="/prescriptions" className={ clsx(classMenuItems, (currentPage == '/prescriptions') && 'current') }>
                                <div className={ clsx(classMenuItemSVG, (currentPage == '/prescriptions') ? 'bg-[#3388FF] filter-invert-img' : '') }>
                                    <img src={ PrescriptionSVG } alt="Recetas" />
                                </div>                                
                                Recetas
                                </Link>                
                            </li>
                            */ }
                            {/* 
                            <li>
                                <Link to="/patients" className={ clsx(classMenuItems, (currentPage == '/patients') && 'current') }>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    className={ clsx(classMenuItemSVG, (currentPage == '/patients') ? 'stroke-current' : 'stroke-black') }
                                >
                                    <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                                    ></path>
                                </svg>
                                Chats
                                </Link>                
                            </li> 
                            */}
                            <li>
                                <Link to="/settings" className={ clsx(classMenuItems, (actualPath === '/settings') && 'current') }>
                                <div className={ clsx(classMenuItemSVG, (actualPath === '/settings') ? 'bg-[#3388FF] filter-invert-img' : '') }>
                                    <img src={ PerfilSVG } alt="Mi Perfil" />
                                </div>                                
                                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    className={ clsx(classMenuItemSVG, (currentPage == '/settings') ? 'stroke-white bg-[#3388FF]' : 'stroke-black') }
                                >
                                    <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                                    ></path>
                                </svg> */}
                                Mi Perfil
                                </Link>                
                            </li>
                        </ul>
                    </div>
                )
            }
        </>    
    );

}