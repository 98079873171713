import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Typography, Container, Grid, Card } from '@material-ui/core';
import {getInfoPatient} from '../redux/actions/patients';
import { Link } from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import { Avatar } from "@material-ui/core";
import {getDate, getFullDate} from '../utils/dates';


const useStyles = makeStyles(theme => ({
  data: {
    margin: '5px 0',
    fontSize: '15px',
    padding: '0px',
    alignItems: 'center'
  },
  button: {
    width: '100%'

  },
  link: {
    textDecoration: 'none'
  },
  grid: {
      alignItems: 'center',
      padding: '5px'
  },
  gridItem: {
      padding: '0 10px'
  }
}));

const Schedule = ({patient, time, consultation_type_id, office_id, appointment_state}) => {
    const dispatch = useDispatch();
    const pageClasses = useStyles();
    const {id, name, surname, id_number, photo} = patient;
    const extraDays = useSelector(state => state.offices.date);


    const date = time.substring(0, 10);
    const hour = time.substring(11, 16);



    


   
    return ( 
      <Link to={{
        pathname: '/info-appointment',
        state: {patient, time, consultation_type_id, office_id, appointment_state}
      }} style={{textDecoration: 'none', color: 'black'}}>
            <Card className={pageClasses.data}>
                <Grid className={pageClasses.grid} container>
                    <Grid className={pageClasses.gridItem} item xs={3}>
                        <Avatar alt="Perfil" src={photo} style={{margin: 'auto'}} />
                    </Grid>
                    <Grid className={pageClasses.gridItem} item xs={6}>
                      <Typography variant="h6">{surname}, {name}</Typography>
                      <Typography variant="subtitle1">DNI: {id_number}</Typography>
                    </Grid>
                    <Grid className={pageClasses.gridItem} item xs={3}>
                      <Typography variant='subtitle2' style={{textAlign: 'center'}}>{hour} hs</Typography>
                      {getDate() != date ? (
                        <Typography variant='subtitle2' style={{textAlign: 'center'}}>{date.substring(5, 10)}</Typography>
                      ) : (
                        <Typography variant='subtitle2' style={{textAlign: 'center'}}>Hoy</Typography>)}  
                    </Grid>
                </Grid>
            </Card>
      </Link>
     );
}
 
export default Schedule;
