import React, { useEffect, Fragment, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInfoPatient,
  updatePatient,
  useEditButton,
} from "../redux/actions/patients";

import { useParams, Link, useNavigate } from "react-router-dom";
import { Avatar, Container, CircularProgress } from "@material-ui/core";


import diff from "../components/diff";
import { setBackButton, setShowFooterMenu } from "../redux/actions/layout";
import { changePatientTags } from "../redux/actions/patients";

import PatientForm from "../components/PatientForm";
import MedicalHistory from "../components/MedicalHistory";
import PatientHeader from "../components/PatientHeader";
import ProfilePatient from "../components/ProfilePatient";

import session from "../utils/session";

import "moment/locale/es";
import Moment from "react-moment";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Typography } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon  from "@material-ui/icons/CancelOutlined";
import { $CombinedState } from "redux";
import SectionLoading from "../components/SectionLoading";
import SectionError from "../components/SectionError";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const InfoPatient = () => {
  const { id, section: sectionParams } = useParams();

  const section = sectionParams || "info"; // TODO:: parche

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const error = useSelector((state) => state.patients.error);
  const loading = useSelector((state) => state.patients.isLoading);

  //const {id} = useParams();
  const patient = useSelector((state) => state.patients.patient);
  const hc_count = useSelector((state) => state.patients.hc_count);
  const editButton = useSelector((state) => state.patients.editButton);
  const updateOk = useSelector((state) => state.patients.update);
  const actualHC = useSelector((state) => state.patients.actualHC);
  
  // PATIENTS
  const inChangePatientTags = useSelector(state => state.patients.inChangePatientTags);
  
  // TAGS
  const tags = useSelector(state => state.offices.tags);
  const inChangeTags = useSelector(state => state.offices.inChangeTags);

  
  const [edit, setEdit] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date()
  ); /*2014-08-18T21:11:54*/

  // const [currentSection, setCurrentSection] = useState('info');

  const [open, setOpen] = useState(false);

  const [patientUpdate, setUpdate] = useState({});

  const [selectedTag, setSelectedTag] = useState(0);
  const [medicalHistory, setMedicalHistory] = useState(false);
  const [medicalHistoryDetails, setMedicalHistoryDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  

  const { professional: professionalData } = session.getUser();
  const professional_id = professionalData.id;

  const [editMode, setEditmode] = useState(false);

  const [requiredFields, setRequiredFields] = useState({
    surname: false,
    name: false,
    telephone: false,
    office_id: false,
  });

  const [patientTags, setPatientTags] = useState([]);

  const [classVisible, setClassVisible] = useState(false);
  const [classLastAppoinmentGray, setClassLastAppoinmentGray] = useState("text-gray-400");
  const [classLastPrescriptions, setClassLastPrescriptions] = useState('invisible ml-4 mr-4 mt-2 max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl');
  const [arrowPosition, setArrowPosition] = useState({
    viewBox:"0 0 13 8",
    width:"13" ,
    height:"8", 
    d:"M1.04289 0.792893C1.43342 0.402369 2.06658 0.402369 2.45711 0.792893L6.75 5.08579L11.0429 0.792893C11.4334 0.402369 12.0666 0.402369 12.4571 0.792893C12.8476 1.18342 12.8476 1.81658 12.4571 2.20711L7.45711 7.20711C7.26957 7.39464 7.01522 7.5 6.75 7.5C6.48478 7.5 6.23043 7.39464 6.04289 7.20711L1.04289 2.20711C0.652369 1.81658 0.652369 1.18342 1.04289 0.792893Z"
  });


  const handleChangeTags = (event) => {

    console.log(event.target.value)
    setSelectedTag(event.target.value)

    const data = {
      type: 'patient',
      tagId: event.target.value, 
      typeValue: id,
      operation: 'add',
      officeId: 326
    }
    
    dispatch(changePatientTags(data));
    

  };

  const removeTag = (tagId) => {
    const data = {
      type: 'patient',
      tagId, 
      typeValue: id,
      operation: 'remove',
      officeId: 326
    }

    dispatch(changePatientTags(data));

  }


  useEffect(() => {
    dispatch(getInfoPatient({ id, professional_id }));
    dispatch(setBackButton(true));
    dispatch(setShowFooterMenu(false));
  }, []);

  useEffect(() => {
    if (patient) {
      
      setUpdate({ ...patient });
      console.log(patientUpdate);
      
      if (patient.born_date) {
        setSelectedDate(returnDate(patient.born_date));
      }

      refreshTagIds();
    } else {
      if (error) {
        const errDetail = error.response?.data?.message;
        if (errDetail) {
          setErrorMessage(errDetail);
        } else {
          setErrorMessage('Error al obtener los datos del paciente.');
        }
      }
    }
  }, [patient]);

  useEffect(() => {
    if (!inChangePatientTags) {
      refreshTagIds();
    }
  }, [inChangePatientTags]);

  /*
  useEffect(() => {
    setUpdate({
      ...patientUpdate,
      born_date: getDate(selectedDate),
    });
  }, [selectedDate]);
  */

  /*
  useEffect(() => {
    if (editButton && Object.keys(patientUpdate).length > 0) {
      validateForm();
    }
  }, [editButton]);
  */

  const handleChange = (e) => {
    setUpdate({
      ...patientUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = (e = null) => {
    if (e) {
      e.preventDefault();
    }
    console.log(patientUpdate);

    const Data = {
      newData: diff(patient, patientUpdate),
      patient_id: id,
      professional_id,
    };

    dispatch(updatePatient(Data));
    console.log(updateOk);

    
  };

  useEffect(() => {
    if(updateOk) {
      console.log('OK')
      handleOpen(open);
      setEditmode(false);
      dispatch(getInfoPatient({ id, professional_id }));
    } else if(updateOk == false) {
      handleOpen(open);
    }
  }, [loading]);


  const refreshTagIds = () => {
    let tagIds = [];
    if (patient?.tags) {
      tagIds = patient.tags.map(a => a.tag_id);
    }
    setPatientTags(tagIds);
  }

  //Funcion que convierte la fecha ingresada y la devuelve en dos formatos(Uno aceptado por la API y otro por los DatePicker)
  const getDate = (date) => {
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    const fullDate = `${year}-${month}-${day}`;
    return fullDate;
  };

  const returnDate = (date) => {
    let year = parseInt(date.slice(0, 4));
    let month = parseInt(date.slice(5, 7));
    let day = parseInt(date.slice(8, 10));
    month = month - 1;

    return new Date(year, month, day);
  };


  const handleClickSection = (section) => {
    // TODO::: no reemplazaba el último parametro
    // navigate(section)
    navigate(`/patients/${id}/${section}`)
    // setCurrentSection(section);
  }

  const handleOpen = () => {
    if (error) return;
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const editPatient = () => {
    setEditmode(true);
  };

  const handleCloseEdit = () => {
    setEditmode(false);
    dispatch(setShowFooterMenu(true));
  };

  const handleMedicalHistory = () => {
    if(medicalHistoryDetails) {
      setMedicalHistoryDetails(null);
    } else {
      setMedicalHistory(!medicalHistory);
    }

  }

  const showSectionName = (sectionName) => {
    return 'Información General';
  }

  const findLastPrescription = (patient) => {

    const prescriptions = patient.prescriptions;

    const sortedPrescriptions = prescriptions.sort((a, b) => new Date(a.date).getTime() < new Date(b.date).getTime());

    const lastPrescription = sortedPrescriptions[sortedPrescriptions.length-1];

    return lastPrescription;

  }

  const showAndHideLastAppoinments = () => {

    setClassVisible( !classVisible );
    console.log(arrowPosition)

    if (!classVisible) {

      setClassLastAppoinmentGray("")
      setArrowPosition( arrowPosition => ({
        rotate: "-rotate-90 duration-300"
      })

      )

    } else {

      setClassLastAppoinmentGray("text-gray-400")
      setArrowPosition( arrowPosition => ({
        rotate: "duration-300"
      })
     )
    }

  }

  const showAndHideLastPrescriptions = () => {

    let classLastPrescriptions;

    setClassVisible( !classVisible );
    console.log(arrowPosition)

    if (classVisible) {

      setClassLastPrescriptions("ml-4 mr-4 mt-2 max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl")
      setArrowPosition( arrowPosition => ({
        viewBox: "0 0 8 12",
        width: "8" ,
        height: "12", 
        d: "M0.542893 11.7071C0.152369 11.3166 0.152369 10.6834 0.542893 10.2929L4.83579 6L0.542893 1.70711C0.152368 1.31658 0.152368 0.683418 0.542892 0.292894C0.933417 -0.0976309 1.56658 -0.097631 1.95711 0.292894L6.95711 5.29289C7.14464 5.48043 7.25 5.73478 7.25 6C7.25 6.26522 7.14464 6.51957 6.95711 6.70711L1.95711 11.7071C1.56658 12.0976 0.933417 12.0976 0.542893 11.7071Z"
      })

      )

    } else {

      setClassLastPrescriptions("invisible ml-4 mr-4 mt-2 max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl")
      setArrowPosition( arrowPosition => ({
        viewBox: "0 0 13 8",
        width: "13",
        height: "8",
        d: "M1.04289 0.792893C1.43342 0.402369 2.06658 0.402369 2.45711 0.792893L6.75 5.08579L11.0429 0.792893C11.4334 0.402369 12.0666 0.402369 12.4571 0.792893C12.8476 1.18342 12.8476 1.81658 12.4571 2.20711L7.45711 7.20711C7.26957 7.39464 7.01522 7.5 6.75 7.5C6.48478 7.5 6.23043 7.39464 6.04289 7.20711L1.04289 2.20711C0.652369 1.81658 0.652369 1.18342 1.04289 0.792893Z"
      })
     )
    }

  }

  const getEventText = (data) => {
    if (data) {
      return (
          <div style={{ border: 'none' }} className={ `bg-[${ data.color }] mb-1 badge`}>
            { data.value }
          </div>
        )
    } else {
      return <div></div>
    }    
  }

  const getEventTextLastest_5 = (data) => {
    if (data) {
      return (
          <div style={{ border: 'none' }} className={ (classLastAppoinmentGray =='') ? `bg-[${ data.color }] mb-1 badge`: `bg-gray-400 mb-1 badge` }>
            { data.value }
          </div>
        )
    } else {
      return <div></div>
    }    
  }
  
  

  if (!errorMessage && (!patient || patient.id !== parseInt(id))) {
    return <SectionLoading loading={ true } />
  }

  if (errorMessage) {
    return (
      <SectionError
       message={ errorMessage }
       actionMessage="Volver al listado"
       actionLink="/patients"
      ></SectionError>
    )
  }

  return (
    <>
      {/* <CircleSpinnerOverlay
        loading={ inChangePatientTags } 
        overlayColor="rgba(0,153,255,0.2)"
      /> */}

      {!editMode && !medicalHistory ? (
        <div className="py-2 h-screen bg-white px-2 overflow-x-auto">


        <div className="fixed top-0 left-0 right-0 z-20">
          <div className="-m-2 mb-4">
            <PatientHeader patient={patient} />
          </div>          

          <nav className="flex justify-center font-sans text-lg space-x-2 mt-1">
            <button onClick={ () => handleClickSection('info')          } className={`font-sans font-semibold px-4 py-1.5 rounded-xl ${ section=='info' ? 'bg-[#3388FF] text-white' : 'text-zinc-400' }`}>Perfil</button>
            <button onClick={ () => handleClickSection('events')        } className={`font-sans font-semibold px-4 py-1.5 rounded-xl ${ section=='events' ? 'bg-[#3388FF] text-white' : 'text-zinc-400' }`}>Turnos</button>
            {/* <button onClick={ () => handleClickSection('prescriptions') } className={`font-semibold px-4 py-1.5 rounded-xl ${ currentSection=='prescriptions' ? 'bg-[#3388FF] text-white' : 'text-zinc-400' }`}>Recetas</button> */}
            <button onClick={ () => handleClickSection('hc')            } className={`font-sans font-semibold px-4 py-1.5 rounded-xl ${ section=='hc' ? 'bg-[#3388FF] text-white' : 'text-zinc-400' }`}>HC</button>
          </nav>
        </div>

          <div style={{height: '47vh'}} className="fixed top-0 left-0 right-0 w-screen z-10 bg-white">

          {/* Este div es solo para compensar el efecto del opacity del header y poder hacer el position fixed */}

          </div> 

          <div className="pt-96 z-0">

          { /* SECCIONES */ }

          { /* INFO */ }
          { (section == 'info') && (

            <>
            
            <ProfilePatient patient={ patient}/>
            {/* <button onClick={editPatient} className="absolute top-12 right-6 z-20">
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.90683 1.31686C10.5899 0.633761 11.5164 0.25 12.4825 0.25C14.4942 0.25 16.1249 1.8808 16.1249 3.89249C16.1249 4.85853 15.7412 5.78502 15.0581 6.46812L10.6517 10.8745C8.63989 12.8863 6.11919 14.3135 3.35907 15.0035L2.5074 15.2165C1.69274 15.4201 0.954828 14.6822 1.15849 13.8675L1.37141 13.0159C2.06144 10.2558 3.48864 7.73506 5.50041 5.72329L9.90683 1.31686ZM12.4825 1.75C11.9142 1.75 11.3693 1.97573 10.9675 2.37752L10.2683 3.0767C10.1445 3.7018 10.4536 4.52884 11.1498 5.22512C11.8461 5.92139 12.6731 6.23046 13.2983 6.10664L13.9974 5.40746C14.3992 5.00566 14.6249 4.46071 14.6249 3.89249C14.6249 2.70922 13.6657 1.75 12.4825 1.75ZM11.9466 7.45828C11.2391 7.22525 10.5906 6.78716 10.0892 6.28578C9.58779 5.78439 9.1497 5.13587 8.91668 4.42834L6.56107 6.78395C4.74154 8.60348 3.45072 10.8833 2.82662 13.3797L2.77041 13.6045L2.99526 13.5483C5.49164 12.9242 7.77147 11.6334 9.591 9.81388L11.9466 7.45828Z" fill="black"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M0 19C0 18.5858 0.335786 18.25 0.75 18.25H16.5C16.9142 18.25 17.25 18.5858 17.25 19C17.25 19.4142 16.9142 19.75 16.5 19.75H0.75C0.335786 19.75 0 19.4142 0 19Z" fill="black"/>
              </svg>
            </button> */}
            </>           
          )}

          
          { /* TURNOS */ }
          { (section == 'events') && (
            <>
            
            <div className="font-sans ml-4 mt-4 font-bold text-base text-black">Próximos Turnos</div>

            {(patient.next_5_events.length > 0) ? (
              patient.next_5_events.reverse().map((item, index) => (
                <div key={`next_5_events_${index}`} className="font-sans ml-4 mr-4 mt-2 max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                  <div className="m-4 grid grid-cols-2">

                    <div>
                      <div className="tracking-wide text-sm text-black font-bold">
                        {patient.name}
                      </div>
                      <div className="mt-2 text-sm text-gray-400">{item.ctype.name}</div>
                    </div>
                    
                    <div className="text-right">
                      { getEventText(item.state_color) }
                      <div className="flex justify-end">
                        <div className="mt-2 ml-2 text-sm font-semibold text-black"><Moment format="DD-MM-YYYY">{item.date_from}</Moment></div>
                        <div className="mt-2 ml-2 text-sm font-semibold text-blue-500"><Moment format="HH:mm">{item.date_from}</Moment>hs</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="font-sans text-center mt-4 text-sm text-gray-500">El paciente no tiene turnos programados.</div>
            )}

            <div className="flex">
              <div className="font-sans ml-4 mt-4 font-bold text-base text-black">Turnos Anteriores</div>
              <button onClick={ () => showAndHideLastAppoinments() } className={`ml-36 mt-4 font-bold text-base text-black ${arrowPosition.rotate}`}>
                  <svg 
                    xmlns="http://www.w3.org/2000/svg"
                    fill="black"
                    viewBox="0 0 13 8"
                    width="13" 
                    height="8" 
                  >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.04289 0.792893C1.43342 0.402369 2.06658 0.402369 2.45711 0.792893L6.75 5.08579L11.0429 0.792893C11.4334 0.402369 12.0666 0.402369 12.4571 0.792893C12.8476 1.18342 12.8476 1.81658 12.4571 2.20711L7.45711 7.20711C7.26957 7.39464 7.01522 7.5 6.75 7.5C6.48478 7.5 6.23043 7.39464 6.04289 7.20711L1.04289 2.20711C0.652369 1.81658 0.652369 1.18342 1.04289 0.792893Z"
                  >
                  </path>
                  </svg>
                </button>
            </div>
            
            {(patient.lastest_5_events.length > 0) ? (
              patient.lastest_5_events.map((item, index) => (
                <div key={`lastest_5_events_${index}`} className="font-sans ml-4 mr-4 mt-2 max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                  <div className="m-4 grid grid-cols-2">

                    <div>
                      <div className={`${classLastAppoinmentGray} tracking-wide text-sm text-black font-bold`}>
                        {patient.name}
                      </div>
                      <div className="mt-2 text-sm text-gray-400">{item.ctype.name}</div>
                    </div>
                    
                    <div className="text-right">
                      { getEventTextLastest_5(item.state_color) }
                      <div className="flex justify-end">
                        <div className={ (classLastAppoinmentGray =='') ? `mt-2 ml-2 text-sm font-semibold text-black` : `${classLastAppoinmentGray} mt-2 ml-2 text-sm font-semibold text-black`}><Moment format="DD-MM-YYYY">{item.date_from}</Moment></div>
                        <div className={ (classLastAppoinmentGray =='') ? `mt-2 ml-2 text-sm font-semibold text-blue-500` : `${classLastAppoinmentGray} mt-2 ml-2 text-sm font-semibold`}><Moment format="HH:mm">{item.date_from}</Moment>hs</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="font-sans text-center mt-4 text-sm text-gray-500">El paciente no tiene historial de turnos.</div>
            )}
            </>
          )}
          


          { /* RECETAS */ }
          { (section == 'prescriptions') && (

            <>
            <div className="ml-4 mr-4 mt-2 bg-white rounded-xl">
            <div className="flex"> 
              <div className="col-span-6 sm:col-span-3">
                <select 
                  id="" 
                  className="mt-4 text-base text-gray-400 shadow-md w-64 h-11 block w-full rounded-md border border-gray-100 bg-white py-2 px-3 shadow-md focus:border-gray-500 focus:outline-none focus:ring-gray-500 sm:text-sm"
                  required
                >
                  <option selected value=''>
                    Seleccionar período
                  </option>
                  <option >Ultima semana</option>
                  <option >Ultimo mes</option>
                  <option >Todo</option>
                </select>
              </div>
              <div className="ml-6 col-span-6 sm:col-span-3">
                <Link to="/new-prescription">
                  <button className="mt-4 justify-center w-11 h-11 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-lg text-white font-bold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">+</button>
                </Link> 
              </div>
            </div>
            </div>

            <div className="ml-4 mt-4 font-bold text-base text-black">Ultima Receta</div>

            {patient.prescriptions ?  
                            
            <div className="ml-4 mr-4 mt-2 max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
            
              <div className="m-4 grid grid-cols-2">
                <div>
                  <div className="tracking-wide text-sm text-black font-bold">
                    {patient.name}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-400"><Moment format="DD-MM-YYYY">{findLastPrescription(patient).date}</Moment></div>
                </div>
              </div>
              <p className="ml-4 mr-4 mb-4 text-sm text-gray-400">{findLastPrescription(patient).description}. Unos minutos antes de hacer la Preparación, retirar del freezer las bananas y los arÍ¡ndonos y colocarlos en la licuadora. Agregar el yogur y la leche. Licuar todo y cada tanto ayudar con una espÍ¡tula hasta lograr una consistencia cremosa.</p>
              <div style={{ display: 'flex', justifyContent:'space-evenly' }}>
                    <div style={{ border: 'none' }} className="p-3 bg-blue-500 mb-4 badge">
                      Enviar x email
                    </div>
                    <div style={{ border: 'none' }} className="p-3 bg-green-500 mb-4 badge">
                      Enviar x whatsapp
                    </div>  
                  </div>
            </div>
            : null}
            
            <div>
              <div className="collapse collapse-arrow">
                <input type="checkbox" className="peer" /> 
                <div className="collapse-title font-bold text-base text-black">
                  Recetas Anteriores
                </div>
                <div className="collapse-content p-0"> 
                {patient.prescriptions ? patient.prescriptions.map((item) => (
                  <div key={`prescriptions_${index}`} className="ml-4 mr-4 mt-2 max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                    <div className="m-4 grid grid-cols-2">
                      <div>
                        <div className="tracking-wide text-sm text-black font-bold">
                          {patient.name}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm text-gray-400"><Moment format="DD-MM-YYYY">{item.date}</Moment></div>
                      </div>
                    </div>
                    <p className="ml-4 mr-4 mb-4 text-sm text-gray-400">{item.description}. Unos minutos antes de hacer la Preparación, retirar del freezer las bananas y los arÍ¡ndonos y colocarlos en la licuadora. Agregar el yogur y la leche. Licuar todo y cada tanto ayudar con una espÍ¡tula hasta lograr una consistencia cremosa.</p>
                    <div style={{ display: 'flex', justifyContent:'space-evenly' }}>
                          <div style={{ border: 'none' }} className="p-3 bg-blue-500 mb-4 badge">
                            Enviar x email
                          </div>
                          <div style={{ border: 'none' }} className="p-3 bg-green-500 mb-4 badge">
                            Enviar x whatsapp
                          </div>  
                        </div>
                  </div>
                  )) : null}
                </div>
              </div>
            </div>

            {/* opción sin usar colpase, agregando una clase "invisible" y usando dos iconos */}
            {/* <div className="flex">
              <div className="ml-4 mt-4 font-bold text-base text-black">Recetas Anteriores</div>
              <button onClick={ () => showAndHideLastPrescriptions() } className="ml-36 mt-4 font-bold text-base text-black">
                <svg 
                  xmlns="http://www.w3.org/2000/svg"
                  fill="black"
                  viewBox={arrowPosition.viewBox}
                  width={arrowPosition.width} 
                  height={arrowPosition.height} 
                >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d={arrowPosition.d}
                >
                </path>
                </svg>
              </button>
            </div>

            {patient.prescriptions ? patient.prescriptions.map((item) => (
            <div className={classLastPrescriptions}>
              <div className="m-4 grid grid-cols-2">
                <div>
                  <div className="tracking-wide text-sm text-black font-bold">
                    {patient.name}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-400"><Moment format="DD-MM-YYYY">{item.date}</Moment></div>
                </div>
              </div>
              <p className="ml-4 mr-4 mb-4 text-sm text-gray-400">{item.description}. Unos minutos antes de hacer la Preparación, retirar del freezer las bananas y los arÍ¡ndonos y colocarlos en la licuadora. Agregar el yogur y la leche. Licuar todo y cada tanto ayudar con una espÍ¡tula hasta lograr una consistencia cremosa.</p>
              <div style={{ display: 'flex', justifyContent:'space-evenly' }}>
                    <div style={{ border: 'none' }} className="p-3 bg-blue-500 mb-4 badge">
                      Enviar x email
                    </div>
                    <div style={{ border: 'none' }} className="p-3 bg-green-500 mb-4 badge">
                      Enviar x whatsapp
                    </div>  
                  </div>
            </div>
            )) : null} */}




            {/* Start old style */}
            {/* <div className="card bg-gray-200 shadow-lg rounded-lg compact mt-3">
              <div className="card-body">
                <h2 className="card-title">
                  Recetas <span className="text-sm text-gray-400">{`${patient.prescriptions_count ? patient.prescriptions_count : '0'}`}</span>
                </h2>

                <div className="mt-2">
                  <table className="table w-full table-compact">
                    <tbody>
                      <tr className="text-center font-bold">
                        <td>Fecha</td>
                        <td>Descripcion</td>
                        <td>Diagnostico</td>
                      </tr>

                      {patient.prescriptions ? patient.prescriptions.map((item) => (
                        <tr key={`prescriptions-${item.id}`}>
      
                          <td className="text-center">
                            <div><Moment format="DD-MM-YYYY">{item.date}</Moment></div>

                          </td> */}
                          {/* <td className="text-center">{item.drugs.name}</td> */}
                          {/* <td className="text-center">{item.description}</td>
                          <td className="text-center">
                            {item.diagnosis} */}
                            {/* <span className="badge badge-primary">{item.drugs.days} dias - c/{item.drugs.hours}hs</span> */}
                          {/* </td>
                        </tr>
                      )) : null}
                      

                    </tbody>
                  </table>

                  <button className="btn btn-sm btn-block mt-3">Ver todos</button>
                </div>
              </div>
            </div> */}
            </>

          )}


          
          { /* HC */ }
          { (section == 'hc') && (

            <>
            <div className="font-sans ml-4 mt-4 font-bold text-base text-black">Historia Clínica</div>

            <MedicalHistory 
                professional_id={professional_id}
                id={id}
                medicalHistoryDetails={medicalHistoryDetails}
                setMedicalHistoryDetails={setMedicalHistoryDetails}
            />
            </>
          )}

</div>


          <button
            className="absolute top-12 left-6 z-50"
            onClick={() => navigate("/patients")}
          >
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.45711 0.292893C7.84763 0.683418 7.84763 1.31658 7.45711 1.70711L3.16421 6L7.45711 10.2929C7.84763 10.6834 7.84763 11.3166 7.45711 11.7071C7.06658 12.0976 6.43342 12.0976 6.04289 11.7071L1.04289 6.70711C0.855357 6.51957 0.75 6.26522 0.75 6C0.75 5.73478 0.855357 5.48043 1.04289 5.29289L6.04289 0.292893C6.43342 -0.0976311 7.06658 -0.0976311 7.45711 0.292893Z" fill="black"/>
            </svg>
          </button>
        </div>
      ) : null}

      {editMode && !medicalHistory ? (
        <div
          className=""
          id=""
        >
          <PatientForm
            handleChange={handleChange}
            validateForm={validateForm}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            requiredFields={requiredFields}
            data={patientUpdate}
            button="Guardar Cambios"
            title="Editar Paciente"
            editMode={editMode}
            handleCloseEdit={handleCloseEdit}
          />

          {/* <button
            className="btn btn-circle btn-sm absolute top-3 left-3"
            onClick={handleCloseEdit}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button> */}
        </div>
      ) : null}

    {
      /*
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade style={{ borderRadius: "20%", maxWidth: "80%" }} in={open}>
          <div>
            {updateOk ? (
              <>
                <Typography
                  variant="h5"
                  align="center"
                  id="transition-modal-title"
                >
                  Paciente editado exitosamente
                </Typography>
                <CheckCircleOutlineIcon
                  style={{
                    fontSize: 100,
                    justifyContent: "center",
                    width: "100%",
                  }}
                  color="primary"
                />
              </>
            ) : (
              <>
                <Typography
                  variant="h5"
                  align="center"
                  id="transition-modal-title"
                >
                  No se pudo editar el paciente
                </Typography>
                <CancelOutlinedIcon
                  style={{
                    fontSize: 100,
                    justifyContent: "center",
                    width: "100%",
                  }}
                  color="primary"
                />
              </>
            )}
          </div>
        </Fade>
      </Modal>
      */
    }
    </>
  );
};

export default InfoPatient;
